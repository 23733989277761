/* Estilos para la tabla de usuarios */

/* Estilos para tipo de usuario */
.user-type {
  font-size: 12px;
  color: #595959;
}

/* Estilos para información del plan de usuario */
.user-plan-info {
  display: flex;
  align-items: center;
}

.user-plan-info .plan-text {
  font-size: 12px;
  color: #555;
}

.plan-info-icon {
  margin-left: 4px;
  cursor: help;
  color: #1890ff;
  font-size: 11px;
}
