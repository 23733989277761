.feature-modal .ant-modal-content {
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 20px 60px rgba(0, 0, 0, 0.15);
}

.feature-modal .ant-modal-header {
  background: linear-gradient(90deg, #f8f8fa, #f5f5f7);
  border-bottom: 1px solid #e8e8e8;
  padding: 20px 28px;
}

.feature-modal .ant-modal-title {
  font-size: 24px;
  font-weight: 700;
  color: #1a1a1a;
  background: linear-gradient(90deg, #FF6B4A 0%, #FF825F 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  padding-right: 30px;
}

.feature-modal .ant-modal-body {
  padding: 30px;
  max-height: 80vh;
  overflow-y: auto;
}

.feature-modal .ant-modal-close {
  color: #666;
  top: 18px;
  right: 18px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 50%;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.feature-modal .ant-modal-close:hover {
  color: #FF6B4A;
  transform: rotate(90deg);
  box-shadow: 0 4px 8px rgba(255, 107, 74, 0.2);
}

.feature-modal-mobile {
  width: 100vw !important;
  top: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  max-width: 100% !important;
}

.feature-modal-mobile .ant-modal-content {
  width: 100vw !important;
  min-height: 100vh !important;
  margin: 0 !important;
  border-radius: 0 !important;
  box-shadow: none !important;
}

.feature-modal-mobile .ant-modal-body {
  padding: 12px !important;
  min-height: calc(100vh - 60px) !important; 
  overflow-y: auto !important;
  display: block !important;
}

.feature-modal-mobile .ant-modal-header {
  border-radius: 0 !important;
  padding: 12px 16px !important;
}

.feature-modal-mobile .ant-modal-title {
  font-size: 18px !important;
}

.feature-modal-mobile .ant-modal-close {
  top: 5px !important;
  right: 5px !important;
  z-index: 1100 !important;
  background-color: rgba(255, 255, 255, 0.9) !important;
  border-radius: 50% !important;
  width: 32px !important;
  height: 32px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15) !important;
}

.feature-modal-mobile .ant-modal-close-x {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  height: 32px !important;
  width: 32px !important;
  line-height: 32px !important;
  font-size: 16px !important;
}

@media (max-width: 768px) {
  .feature-modal {
    width: 100% !important;
    max-width: 100% !important;
    margin: 0 !important;
    top: 0 !important;
    padding: 0 !important;
  }

  .feature-modal .ant-modal-content {
    width: 100vw !important;
    min-height: 100vh !important;
    margin: 0 !important;
    border-radius: 0 !important;
    box-shadow: none !important;
  }
  
  .feature-modal .ant-modal-body {
    padding: 16px;
    min-height: calc(100vh - 60px) !important;
    max-height: none !important;
    overflow-y: auto;
    display: block !important;
  }

  .feature-modal .ant-modal-header {
    border-radius: 0 !important;
  }

  .feature-modal .ant-modal-close {
    top: 5px !important;
    right: 5px !important;
    z-index: 1100 !important;
    background-color: rgba(255, 255, 255, 0.9) !important;
    border-radius: 50% !important;
    width: 32px !important;
    height: 32px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15) !important;
  }
  
  .feature-modal .ant-modal-close-x {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    height: 32px !important;
    width: 32px !important;
    line-height: 32px !important;
    font-size: 16px !important;
  }
} 