.animated-logo-container {
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 1001;
  display: flex;
  position: fixed;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background: rgba(255, 255, 255, 0.9);
}

.animated-logo {
  width: 300px;
  height: auto;
  margin: 0 auto;
}

.logo-fill {
  fill: var(--outrageouse-orange, #ff5722);
}

/* Audio bar animations */
.bar-1 {
  transform-origin: bottom;
  animation: audioWave1 1.8s ease-in-out infinite;
}

.bar-2 {
  transform-origin: bottom;
  animation: audioWave2 1.6s ease-in-out infinite;
}

.bar-3 {
  transform-origin: bottom;
  animation: audioWave3 1.7s ease-in-out infinite;
}

.bar-4 {
  transform-origin: bottom;
  animation: audioWave4 1.5s ease-in-out infinite;
}

@keyframes audioWave1 {
  0%, 100% {
    transform: scaleY(0.8);
  }
  50% {
    transform: scaleY(0.3);
  }
}

@keyframes audioWave2 {
  0%, 100% {
    transform: scaleY(0.5);
  }
  50% {
    transform: scaleY(1);
  }
}

@keyframes audioWave3 {
  0%, 100% {
    transform: scaleY(0.6);
  }
  50% {
    transform: scaleY(0.9);
  }
}

@keyframes audioWave4 {
  0%, 100% {
    transform: scaleY(0.7);
  }
  50% {
    transform: scaleY(0.4);
  }
} 