/* Mobile releases styles */
.mobile-releases-container {
  width: 100%;
  padding: 0 0px;
  min-height: calc(100vh - 120px);
  padding-bottom: 80px; /* Space for the floating action button */
}

.mobile-releases-header {
  display: none; /* Hide the duplicate header */
}

.mobile-releases-filter {
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

/* Common styles for all input containers */
.mobile-search-filter,
.mobile-status-filter,
.mobile-date-filter {
  margin-bottom: 0;
}

/* Matching desktop input styles */
.mobile-search-filter .ant-input,
.mobile-status-filter .ant-select-selector,
.mobile-date-filter .ant-picker {
  height: 40px !important;
  border-radius: 8px !important;
  box-shadow: none !important;
  border-color: #e8e8e8 !important;
  background-color: #fff !important;
  transition: all 0.3s ease;
  font-size: 14px !important;
}

/* Input placeholder styles */
.mobile-search-filter .ant-input::placeholder,
.mobile-status-filter .ant-select-selection-placeholder,
.mobile-date-filter .ant-picker-input > input::placeholder {
  color: #bfbfbf !important;
  font-size: 14px !important;
}

/* Search input specific styles to exactly match the desktop */
.mobile-search-filter .ant-input-search .ant-input-affix-wrapper {
  border-radius: 8px !important;
  border-color: #e8e8e8 ;
  background-color: #fff !important;
  height: 40px !important;
  padding: 0 11px !important;
  box-shadow: none !important;
}

.mobile-search-filter .ant-input-search .ant-input {
  margin-right: 20px;
  height: 40px ;
  color: #555 !important;
  font-size: 14px !important;
  background-color: transparent !important;
}

.mobile-search-filter .ant-input-search .ant-input::placeholder {
  color: #bfbfbf !important;
  font-size: 14px !important;
}

.mobile-search-filter .ant-input-search-icon {
  color: #bfbfbf !important;
  font-size: 16px !important;
  padding: 0 !important;
}

/* Remove the search button */
.mobile-search-filter .ant-input-search .ant-input-search-button {
  position: absolute;
  top: 7px;
  border: none;
  background: transparent;
  z-index: 1;
  width: 15px;
  box-shadow: none;
  padding: 0;
  background-color: transparent;
  right: 10px;
}

.mobile-search-filter .ant-input-search .ant-input-search-button svg{
  width: 15px;
  height: 15px;
}

.mobile-search-filter .ant-input-suffix {
  position: relative;
  color: #bfbfbf !important;
  right: 19px;
  top: 2px;
}

.mobile-search-filter .ant-input-affix-wrapper:focus,
.mobile-search-filter .ant-input-affix-wrapper-focused,
.mobile-status-filter .ant-select-focused .ant-select-selector,
.mobile-date-filter .ant-picker-focused {
  border-color: #d9d9d9 !important;
  box-shadow: none !important;
}

.mobile-search-filter .ant-input-affix-wrapper:hover,
.mobile-status-filter .ant-select-selector:hover,
.mobile-date-filter .ant-picker:hover {
  border-color: #d9d9d9 !important;
}

.mobile-status-filter .ant-select-selector {
  padding: 0 11px !important;
  display: flex;
  align-items: center;
}

.mobile-status-filter .ant-select-selection-item {
  line-height: normal !important;
  color: #555 !important;
}

.mobile-status-filter .ant-select-clear{
  opacity: 1;
  font-size: 14px;
  right: 13px;
}
.mobile-date-filter .ant-picker-range .ant-picker-clear{
  opacity: 1;
}
.mobile-date-filter .ant-picker {
  width: 100% !important;
}

.mobile-date-filter .ant-picker-input > input {
  height: 40px !important;
  font-size: 14px !important;
  color: #555 !important;
}

/* Card design resembling table rows */
.mobile-release-card {
  display: flex;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.06);
  padding: 12px;
  margin-bottom: 16px;
  position: relative;
  transition: all 0.2s ease;
  border: 1px solid #f0f0f0;
  cursor: pointer;
}

.mobile-release-card:active {
  background-color: #f9f9f9;
}

.mobile-release-card:hover {
  border-color: #e6e6e6;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
}

.mobile-release-cover {
  align-self: center;
  width: 60px;
  height: 60px;
  border-radius: 4px;
  overflow: hidden;
  margin-right: 12px;
  flex-shrink: 0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobile-release-cover img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.default-album-cover {
  width: 100%;
  height: 100%;
  object-fit: contain;
  padding: 8px;
  background-color: #f5f5f5;
}

.avatar-cover {
  width: 100% !important;
  height: 100% !important;
}

.mobile-release-info {
  flex: 1;
  min-width: 0;
  padding-right: 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.mobile-release-title {
  font-size: 15px;
  font-weight: 600;
  color: #333;
  margin: 0 0 3px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mobile-release-artist {
  font-size: 13px;
  color: #666;
  margin: 0 0 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mobile-release-details {
  display: flex;
  flex-wrap: wrap;
  font-size: 12px;
  color: #666;
  margin-top: auto;
}

.mobile-release-date {
  margin-right: 0;
  flex: 3;
}

.mobile-release-date .label {
  font-weight: 500;
  margin-right: 4px;
}

.mobile-release-status {
  display: flex;
  align-items: center;
  flex: 1;
}

/* Status tag styles matching desktop exactly */
.mobile-status-tag {
  font-size: 11px;
  padding: 0 10px;
  line-height: 20px;
  height: 20px;
  border-radius: 10px;
  text-transform: capitalize;
  font-weight: 500;
  white-space: nowrap;
  border: none;
}

.mobile-status-tag.draft, .mobile-status-tag.draftclient {
  background-color: var(--draftBackgroundColor);
  color: var(--draftColor);
}

.mobile-status-tag.readyToSent {
  background-color: var(--readyToSentBackgroundColor);
  color: var(--readyToSentColor);
}

.mobile-status-tag.pendingDistribution, .mobile-status-tag.pendingDistributionClient {
  background-color: var(--pendingDistributionBackgroundColor);
  color: var(--pendingDistributionColor);
}

.mobile-status-tag.readyToSentClient, .mobile-status-tag.inReview, .mobile-status-tag.readyToSentclient {
  background-color: var(--readyToSentClientBackgroundColor);
  color: var(--readyToSentClientColor);
}

.mobile-status-tag.inReviewClient{
  background-color: var(--inReviewClientBackgroundColor);
  color: var(--inReviewClientColor);
}

.mobile-status-tag.rejected, .mobile-status-tag.rejectedclient {
  background-color: var(--rejectedBackgroundColor);
  color: var(--rejectedColor);
}

.mobile-status-tag.rejected_team, .mobile-status-tag.rejected_teamclient {
  background-color: var(--rejectedTeamBackgroundColor);
  color: var(--rejectedTeamColor);
}

.mobile-status-tag.delivered, .mobile-status-tag.deliveredclient {
  background-color: var(--deliveredBackgroundColor);
  color: var(--deliveredColor);
}

.mobile-status-tag.published, .mobile-status-tag.publishedclient {
  background-color: var(--publishedBackgroundColor);
  color: var(--publishedColor);
}

.mobile-release-actions {
  position: absolute;
  right: 12px;
  top: 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  z-index: 2;
}

.mobile-release-actions .action-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #f5f5f5;
  color: #666;
  transition: all 0.2s ease;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08);
}

.mobile-release-actions .action-btn:hover {
  background-color: #e6f7ff;
  color: #1890ff;
}

.mobile-release-actions .action-btn.delete:hover {
  background-color: #fff1f0;
  color: #ff4d4f;
}

.mobile-release-empty {
  text-align: center;
  padding: 40px 0;
  color: #999;
  background-color: #fff;
  border-radius: 8px;
  margin-top: 20px;
}

.mobile-release-pagination {
  margin: 20px 0;
  display: flex;
  justify-content: center;
}

.mobile-release-pagination .ant-pagination-item-active {
  border-color: #ff5f2e;
}

.mobile-release-pagination .ant-pagination-item-active a {
  color: #ff5f2e;
}

/* Loading state */
.mobile-releases-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  background-color: #fff;
  border-radius: 8px;
  margin-top: 20px;
}

/* Responsive styles - Update for iOS compatibility */
@media (max-width: 480px) {
  .mobile-release-actions {
    flex-direction: row;
    right: 12px;
    transform: none;
  }
  
  .mobile-release-info {
    padding-right: 0;
    padding-top: 0;
  }
  
  .mobile-release-actions .action-btn {
    width: 28px;
    height: 28px;
  }
}

@media (max-width: 380px) {
  .mobile-release-card {
    padding: 10px;
  }
  
  .mobile-release-cover {
    width: 55px;
    height: 55px;
    margin-right: 10px;
  }
  
  .mobile-release-title {
    font-size: 14px;
  }
  
  .mobile-release-artist {
    font-size: 12px;
  }
}

/* New Release button matching desktop styling */
.mobile-new-release-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background-color: #ff5f2e;
  color: white !important;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 12px rgba(255, 95, 46, 0.5);
  z-index: 1000;
  transition: all 0.2s ease;
  border: none;
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 95, 46, 0.7);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(255, 95, 46, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 95, 46, 0);
  }
}

.mobile-new-release-button:hover,
.mobile-new-release-button:focus,
.mobile-new-release-button:active {
  background-color: #ff4713;
  color: white !important;
  text-decoration: none;
  transform: scale(1.05);
}

.mobile-new-release-button .anticon {
  font-size: 24px;
}

/* Make sure button is visible on different screen sizes */
@media (max-width: 480px) {
  .mobile-new-release-button {
    bottom: 30px;
    right: 30px;
  }
}

@media (max-width: 380px) {
  .mobile-new-release-button {
    width: 52px;
    height: 52px;
    bottom: 24px;
    right: 24px;
  }
  
  .mobile-new-release-button .anticon {
    font-size: 22px;
  }
} 