.pricing-container {
  padding: 0;
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
  min-height: 100vh;
  background: #ffffff;
}

/* Override container styles for pricing screen */
.container-box .wizard-box.pricing-screen {
  padding: 40px 5% !important;
  max-width: 1400px !important;
  width: 100% !important;
  background-color: #fff !important;
  border-radius: 16px !important;
  margin: auto !important;
  margin-top: 30px !important;
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.06) !important;
  text-align: left !important;
  position: relative !important;
  overflow: visible !important;
}

.pricing-container h2 {
  text-align: center;
  margin: 0 0 20px;
  color: #1a1a1a;
  font-size: 42px;
  font-weight: 800;
  background: linear-gradient(90deg, #FF6B4A 0%, #FF825F 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  letter-spacing: -0.5px;
}

.pricing-description {
  text-align: center;
  color: #4a4a4a;
  margin-bottom: 40px;
  font-size: 18px;
  line-height: 1.6;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  padding: 0;
  font-weight: 400;
  letter-spacing: -0.2px;
}

/* New pricing cards grid */
.pricing-cards-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  margin: 60px auto 50px;
  max-width: 1200px;
  justify-items: center;
}

/* Error message styling */
 .pricing-error-field {
  background: rgba(255, 77, 79, 0.08);
  border-radius: 8px;
  padding: 12px 16px;
  color: #ff4d4f;
  font-size: 14px;
  margin: 20px auto;
  display: flex;
  align-items: center;
  max-width: 500px;
}

.pricing-error-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #ff4d4f;
  color: white;
  font-weight: bold;
  margin-right: 12px;
  font-size: 12px;
}

/* Action buttons container */
/* .action-buttons {
  margin: 20px 0;
  text-align: left;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 16px;
}

.back-btn {
  color: #666;
  margin-right: 16px;
  padding: 0 20px;
  height: 48px;
  border-radius: 50px;
  transition: all 0.3s ease;
}

.back-btn:hover {
  color: #1a1a1a;
  background: rgba(0, 0, 0, 0.04);
} 
.reg-btn {
  display: flex;
  gap: 20px;
  margin-top: 40px;
  margin-bottom: 50px;
  align-items: center;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 16px;
} */

.align-center {
  justify-content: center;
}

.align-right {
  justify-content: flex-end;
}

.align-left {
  justify-content: flex-start;
}

/* Ant Design button overrides */
.ant-btn.pricing-btn {
  transition: all 0.3s ease;
  border-radius: 6px;
  font-weight: 500;
  height: 44px;
  padding: 0 24px;
}

.ant-btn-text.back-btn {
  color: #666;
}

.ant-btn-text.back-btn:hover {
  color: #FF6B4A;
  border-color: #FF6B4A;
  background: rgba(255, 107, 74, 0.05);
}

.ant-btn-primary.pricing-btn {
  background: linear-gradient(90deg, #FF6B4A 0%, #FF825F 100%);
  border: none;
  box-shadow: 0 4px 12px rgba(255, 107, 74, 0.2);
}

.ant-btn-primary.pricing-btn:hover {
  background: linear-gradient(90deg, #FF825F 0%, #FF9470 100%);
  transform: translateY(-1px);
  box-shadow: 0 6px 16px rgba(255, 107, 74, 0.25);
}

.ant-btn-primary.pricing-btn:disabled {
  opacity: 0.7;
  cursor: not-allowed;
  background: linear-gradient(90deg, #FF6B4A 0%, #FF825F 100%);
  border: none;
  transform: none;
  box-shadow: none;
}

/* Billing toggle styles */
.billing-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 60px;
  background: #f8f8fa;
  padding: 8px;
  border-radius: 50px;
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.08);
  position: relative;
  gap: 8px;
  transition: all 0.3s ease;
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.billing-toggle.yearly {
  margin-top: 40px;
}

.billing-toggle .label {
  font-size: 16px;
  font-weight: 600;
  color: #666;
  transition: all 0.3s ease;
  padding: 10px 24px;
  border-radius: 50px;
  z-index: 2;
  cursor: pointer;
  text-align: center;
  flex: 1;
}

.billing-toggle .monthly-label {
  letter-spacing: 0.3px;
  text-transform: none;
}

.billing-toggle .yearly-label {
  letter-spacing: 0.3px;
}

/* Active label styling */
.billing-toggle.monthly .monthly-label,
.billing-toggle.yearly .yearly-label {
  color: #ffffff;
  background: linear-gradient(90deg, #FF6B4A 0%, #FF825F 100%);
  box-shadow: 0 4px 12px rgba(255, 107, 74, 0.25);
}

.discount-badge {
  background: #FF6B4A;
  color: white !important;
  padding: 8px 20px;
  border-radius: 50px;
  font-size: 14px !important;
  font-weight: 700 !important;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  box-shadow: 0 8px 20px rgba(255, 107, 74, 0.25);
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -45px;
  white-space: nowrap;
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    box-shadow: 0 4px 12px rgba(255, 107, 74, 0.2);
  }
  50% {
    box-shadow: 0 8px 20px rgba(255, 107, 74, 0.4);
  }
  100% {
    box-shadow: 0 4px 12px rgba(255, 107, 74, 0.2);
  }
}

/* View all features styling */
.view-all-features {
  text-align: center;
  margin: 40px 0;
}

.view-all-link {
  background: none;
  border: none;
  color: #FF6B4A;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  padding: 10px 18px;
  transition: all 0.3s ease;
  border-radius: 8px;
  text-decoration: none;
  position: relative;
  display: inline-flex;
  align-items: center;
  box-shadow: 0 2px 8px rgba(255, 107, 74, 0.1);
  background: rgba(255, 107, 74, 0.05);
}

.view-all-link:hover {
  background: rgba(255, 107, 74, 0.1);
  text-decoration: none;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(255, 107, 74, 0.15);
}

.view-all-link::after {
  content: '→';
  margin-left: 8px;
  font-size: 20px;
  transition: transform 0.3s ease;
}

.view-all-link:hover::after {
  transform: translateX(3px);
}

/* Switch component override */
.billing-switch {
  display: none !important;
}

/* Media Queries for Responsiveness */
@media screen and (max-width: 992px) {
  .pricing-cards-grid {
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 24px;
    margin: 40px auto;
  }
}

@media screen and (max-width: 768px) {
  .pricing-container h2 {
    font-size: 36px;
  }
  
  .pricing-description {
    font-size: 16px;
  }
  
  .pricing-cards-grid {
    grid-template-columns: 1fr;
    max-width: 400px;
    margin: 30px auto;
  }
}

@media screen and (min-width: 1440px) {
  .pricing-container {
    padding: 0;
  }
  
  .container-box .wizard-box.pricing-screen {
    padding: 60px 5% !important;
  }
  
  .pricing-description {
    margin-bottom: 40px;
  }
  
  .pricing-cards-grid {
    gap: 40px;
  }
}

/* Current plan section styling */
.current-plan-info {
  background-color: #f6ffed;
  border: 1px solid #b7eb8f;
  border-radius: 10px;
  padding: 20px 24px;
  margin-bottom: 24px;
  text-align: center;
}

.current-plan-notice {
  font-size: 16px;
  margin-bottom: 8px;
  color: #333;
}

.current-plan-notice strong {
  color: #52c41a;
  font-weight: 700;
}

.current-plan-change {
  font-size: 14px;
  color: #666;
  margin-bottom: 16px;
}

/* Payment action section styles */
.payment-action {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px dashed #b7eb8f;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.selected-plan-text {
  font-size: 16px;
  margin-bottom: 12px;
  color: #333;
}

.selected-plan-text strong {
  color: #FF6B4A;
  font-weight: 700;
}

.payment-btn {
  min-width: 200px;
  background: linear-gradient(90deg, #FF6B4A 0%, #FF825F 100%);
  color: white;
  border: none;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 8px;
  box-shadow: 0 4px 12px rgba(255, 107, 74, 0.2);
}

.payment-btn:hover {
  background: linear-gradient(90deg, #FF825F 0%, #FF9470 100%);
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(255, 107, 74, 0.3);
}

/* Responsive styles for mobile */
@media (max-width: 768px) {
  .payment-action {
    width: 100%;
  }
  
  .payment-btn {
    width: 100%;
    max-width: 300px;
  }
}

/* Billing toggle styles */
.billing-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px 0;
  position: relative;
  background: #f5f5f7;
  border-radius: 30px;
  padding: 6px;
  width: 280px;
  margin-left: auto;
  margin-right: auto;
}

.billing-toggle .label {
  padding: 10px 20px;
  border-radius: 20px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  z-index: 1;
  flex: 1;
  text-align: center;
}

.billing-toggle .monthly-label {
  color: #333;
}

.billing-toggle .yearly-label {
  color: #666;
}

.billing-toggle.monthly .monthly-label {
  color: white;
  background: linear-gradient(90deg, #FF6B4A 0%, #FF825F 100%);
  box-shadow: 0 4px 8px rgba(255, 107, 74, 0.2);
}

.billing-toggle.yearly .yearly-label {
  color: white;
  background: linear-gradient(90deg, #FF6B4A 0%, #FF825F 100%);
  box-shadow: 0 4px 8px rgba(255, 107, 74, 0.2);
}

.billing-switch {
  position: absolute !important;
  opacity: 0;
  pointer-events: none;
}

/* Pricing cards grid */
.pricing-cards-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  margin: 40px 0;
}

@media (max-width: 992px) {
  .pricing-cards-grid {
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  }
}

@media (max-width: 768px) {
  .pricing-cards-grid {
    grid-template-columns: 1fr;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  }
} 