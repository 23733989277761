.royalt {
  display: flex;
  height: 100px;
  justify-content: left;
  margin-bottom: 10px;
}
.select-roy {
  margin: 20px 0;
  width: 100%;
}
.select-roy label {
  display: block;
  font-weight: 700;
  font-size: 15px;
}
.searchers.select-roy .relative-input {
  min-width: 200px;
}
.searchers.select-roy .relative-input .ant-select-selector {
  background-color: #fff !important;
}
.select-roy.middle {
  padding: 0 40px;
}
.button-filter {
  display: flex;
  justify-content: center;
  margin: 20px 0;
  margin: auto;
  margin-bottom: 40px;
  margin-top: 20px;
}

.royalty-graph .ant-card {
  border-radius: 20px;
  border: 1px solid #eeeeee !important;
}

.royalty-graph .ant-card-body {
  min-height: 350px;
  height: auto;
  max-height: 450px;
  justify-content: center;
  display: flex;
}

/********** analytics-royalty **********/
.analytics-royalty {
  min-height: 50vh;
}
/********** table-royalty **********/
.content-table-admin.roylts .ant-table {
  overflow-x: auto;
  border-radius: 20px;
}
.content-table-admin.roylts .title-category {
  height: 55px;
  width: 150px;
}
.link-files-royalty {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
}
.link-files-royalty a svg {
  width: 25px;
  height: 25px;
  vertical-align: text-bottom;
}
.link-files-royalty a svg:hover {
  filter: brightness(1.5);
}
/********** Medias **********/
@media (max-width: 785px) {
  .royalt {
    flex-direction: column;
  }
  .select-roy.middle {
    padding: 0 0px;
  }
}
