.header-release {
  margin-bottom: 30px;
  margin-top: 30px;
  padding-left: 70px;
  padding-right: 70px;
}

.header-release .cover {
  max-width: 300px;
  border-radius: 12px;
  width: 100%;
  height: auto;
}

.header-release .title-wraper {
  max-width: calc(100% - 60px);
}

.header-release .img-c {
  display: inline-block;
  position: relative;
}
/* Optional same height for contributors cards
.header-release .roles-cards {
  min-height: 300px;
}
*/

/* Optional bg color on title
.header-release .bg-title {
  background: #fff;
  border-radius: 8px;
}
.header-release .bg-title div {
  padding: 10px;
}
.header-release .bg-title h1 {
  margin-bottom: 0px;
}*/

.cover-edit ul {
  min-width: 170px;
  padding: 14px 21px 14px 11px;
}

.cover-edit li {
  color: rgba(69, 69, 69, 0.6);
  padding: 0;
  padding-top: 11px;
  padding-bottom: 10px;
  font-size: 16px;
  color: #343434;
  border-bottom: 1px solid #e9e9e9;
}

.cover-edit li:last-child {
  border-bottom: none;
}

.cover-edit li svg {
  vertical-align: top;
  margin-right: 15px;
}

.menu-cover {
  background: rgb(142, 142, 142, 0.6);
  position: absolute;
  right: 15px;
  top: 15px;
  border-radius: 50px;
  width: 40px;
  height: 40px;
  font-size: 27px;
  line-height: 2;
  color: #fff;
  cursor: pointer;
  box-shadow: 1px 1px 3px 1px #4c4c4cc9;
}

.menu-cover svg {
  text-align: center;
  width: 100%;
}

.header-release .stats {
  font-weight: 500;
  font-size: 16px;
  color: #f63e3e;
  padding-bottom: 10px;
  padding-top: 15px;
}

.header-release .stats .mays {
  display: inline-block;
  text-transform: uppercase;
}

.header-release .stats .caps {
  display: inline-block;
  text-transform: capitalize;
}

.edit-release-cover {
  color: var(--malibu-orig);
  font-size: 50px;
  text-align: center;
}
/****** broke image *******/
.header-release .cover.broken {
  max-width: 300px;
  height: auto;
  border-radius: 12px;
}

.header-release .img-c.broke {
  display: inline;
}

.header-release .img-c.broke .menu-cover {
  top: unset;
  right: 1px;
  width: 35px;
  height: 35px;
  font-size: 22px;
}
.select-role.selector-general.multiselect {
  max-width: 80%;
}

.select-role.selector-general.multiselect.ant-select-multiple.ant-select-allow-clear
  .ant-select-selector {
  padding-right: 10px;
}

.header-release .upc-input button {
  border-color: var(--outrageouse-orange);
  background: var(--outrageouse-orange);
  border-radius: 48px !important;
  margin-left: 12px;
}

.header-release .upc-input .ant-input-group-addon {
  background: none;
  border-color: transparent;
  padding: 0px 12px 0px 0px;
}

.header-release .upc-input .ant-input-affix-wrapper {
  border-radius: 5px;
}

.header-release .upc-input .ant-input-affix-wrapper:hover {
  border-radius: 5px;
  border-color: var(--outrageouse-orange);
}

.header-release .upc-input input {
  color: #898686;
  min-height: 30px;
}

/****** medias *******/
@media (max-width: 992px) {
  .header-release .cover-header-container {
    text-align: center;
  }
  .header-release .img-c.broke .menu-cover {
    top: unset;
    right: 0px;
  }
}
@media (max-width: 575px) {
  .header-release {
    padding-left: 35px;
    padding-right: 35px;
  }
}
