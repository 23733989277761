.info-icon-container {
  display: inline-flex;
  line-height: 1;
  margin-left: 4px;
  vertical-align: middle;
  position: relative;
  z-index: 100;
}

.info-icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: #f5f5f5;
  cursor: help;
  transition: background-color 0.2s ease;
}

.info-icon-wrapper:hover {
  background-color: #e6e6e6;
}

.info-icon {
  color: #bfbfbf;
  font-size: 12px;
}

.info-icon-wrapper:hover .info-icon {
  color: #ff5722;
}

/* Global Ant Design tooltip override */
.ant-tooltip {
  max-width: 300px !important;
  z-index: 1099 !important;
}

.ant-tooltip-inner {
  background-color: #333 !important;
  padding: 8px 12px !important;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2) !important;
  border-radius: 4px !important;
  color: white !important;
  font-size: 13px !important;
  line-height: 1.5 !important;
  text-align: left !important;
}

/* Fix positioning issue - first hide tooltip during transition */
.ant-tooltip.ant-zoom-big-fast-enter {
  visibility: hidden;
}

/* Then show and fade in after a delay (when positioned) */
.ant-tooltip.ant-zoom-big-fast-enter-active {
  visibility: visible;
  animation: fadeInDelayed 0.2s ease forwards;
}

@keyframes fadeInDelayed {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Custom tooltip styling */
.info-tooltip {
  max-width: 300px !important;
}

.info-tooltip .ant-tooltip-arrow {
  display: none;
}

.info-tooltip .formatted-tooltip-content {
  text-align: left;
  white-space: normal;
  line-height: 1.5;
  padding: 2px;
}

/* Mobile styling */
@media (max-width: 768px) {
  .ant-tooltip {
    max-width: 250px !important;
  }
  
  .info-icon-wrapper {
    width: 20px;
    height: 20px;
  }
  
  .info-icon {
    font-size: 14px;
  }
} 