.terms-layout {
  min-height: 100%;
  padding: 40px 100px 80px;
  text-align: justify;
  overflow-y: auto;
  background-color: #fff;
}
.terms-layout  h3{
  padding-top: 25px;
  font-weight: bold;
  font-size: 23px;
}
.terms-layout  h2{
  font-size: 28px;
  font-weight: bold;
}
.terms-layout .list-ltrs{
  list-style-type: lower-alpha;
}
.container-box.sign.register{
  padding: 0px;
}
.container-box.sign.register .terms-layout{
  padding: 30px;
}
@media (max-width: 650px) {
  .terms-layout {
    padding: 40px 40px 80px;
  }
}