.label-with-info {
  position: relative;
  width: 100%;
  box-sizing: border-box;
}

.label-with-info > div:first-child {
  width: 100%;
}

/* Style for labels with info icons */
.label-with-info label.allWidth {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

/* Set spacing for info icons */
.label-with-info label.allWidth .info-icon-container {
  margin-left: 6px;
}

/* Hide the old positioned icon */
.label-with-info .radio-options + .info-icon-container {
  display: none;
}

/* For smaller screens */
@media (max-width: 768px) {
  .label-with-info {
    margin-bottom: 20px;
  }
} 