/********* Counts *********/
.counts-cards .ant-col {
  margin-bottom: 25px;
}
.counts-cards .ant-card,
.general-card .ant-card {
  border: 1px solid #eeeeee;
  border-radius: 20px;
  box-shadow: var(--shadow-elevation-high);
}
.counts-cards .ant-card-body {
  text-align: left;
  padding: 18px 26px 15px 26px;
  /* create a gradient shadow with tree colors */
}
.counts-cards .ant-card-body p {
  max-width: 100%;
  font-size: 13px;
  display: flex;
  color: var(--color-title);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.counts-cards .count-number {
  font-size: 24px;
  font-weight: 400;
  color: #454545e6;
}
.gradient-shadow {
  background: var(
    --gradient-1,
    linear-gradient(
      90deg,
      #ff5f2e 0%,
      #ff9fff 53.65%,
      #73c7ff 100%,
      #73c7ff 100%
    )
  );
  height: 30px;
  border-radius: 0px 0px 20px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: calc(100% - 18px);
  bottom: -2px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  z-index: 1;
  opacity: 0.9;
}
.counts-cards .loader-count {
  color: var(--outrageouse-orange);
}
.counts-cards .ant-card-body svg.plus-count {
  top: 13px;
  right: 13px;
  width: 30px;
  height: 30px;
  padding: 5px;
  border-radius: 5px;
  position: absolute;
  /* background: rgba(240, 240, 240, 0.9); */
  /* background: rgba(254, 97, 54, 0.9); */
  background-color: #ff5f2e;
}
.counts-cards.flex-center {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-left: 100px;
  margin-right: 100px;
}
/********* Cards *********/
.container-box.dashboard {
  margin-top: 0px;
  padding: 20px 60px 30px 60px;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: 1380px;
  width: 100%;
  border: none;
}
.general-card {
  padding-bottom: 25px;
}
.general-card .list-name {
  margin-top: 5px;
  margin-left: 25px;
  color: var(--color-title);
  width: auto;
}
.general-card .ant-card-body {
  text-align: left;
  padding: 10px;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 3px;
}
.general-card .list-rel {
  padding: 0px 0px 5px;
  padding-left: 0px;
}
.card-help{
  padding: 20px;
}
.card-help .link-help{
  color: var(--outrageouse-orange);
  font-weight: 500;
  text-decoration: underline;
  display: inline-block;
}
.list-rel .add-rel {
  width: 151px;
  height: 151px;
  padding: 55px;
  cursor: pointer;
  background: rgba(240, 240, 240, 0.9);
  border-radius: 10px;
}
.list-rel .empty-rel {
  width: 151px;
  height: 151px;
  padding: 19px 0;
  background: rgba(240, 240, 240, 0.9);
  border-radius: 10px;
  margin-left: auto;
  margin-right: auto;
}
.list-rel .empty-rel.ant-empty-normal {
  margin-bottom: 32px;
  margin-top: 0;
  align-content: center;
}
.list-rel .empty-rel.ant-empty-normal .ant-empty-description {
  width: 92px;
  position: relative;
  margin: auto;
}
.list-rel .no-release-description {
  padding-top: 16px;
  font-weight: 500;
  font-size: 12px;
  color: var(--subtext);
  font-style: normal;
}

.list-cd {
  width: 100%;
  padding: 0 15px 10px 20px;
  overflow-x: auto;
  min-height: 207px;
  margin-bottom: 0px;
  overflow-y: hidden;
  display: inline-flex;
  list-style-type: none;
  gap: 15px;
}
.list-cd .cd-data {
  display: inline-block;
  padding-bottom: 10px;
  margin-bottom: 0px;
  width: 151px;
  text-align: left;
  margin-right: 5px;
  position: relative;
}
.list-cd.dash_home .add-space.cd-data {
  margin-right: 20px;
  margin-top: 10px;
}
.list-cd.dash_home .add-space.cd-data:first-child,
.list-cd.dash_home .cd-data:first-child {
  margin-left: 25px;
}
.list-cd.dash_home .cd-data {
  margin-right: 20px;
}
.cd-data.cd-release {
  margin-right: 15px;
}
.list-cd .cd-data img, .list-cd .cd-data .avatar-list{
  height: 151px !important;
  width: 151px !important;
  border-radius: 10px;
}
.empty-stylecard {
  padding-bottom: 0px;
  min-height: 188px;
}
.empty-stylecard .cd-data {
  padding-bottom: 0px;
}
.cd-data p.album {
  padding-top: 7px;
  font-size: 13px;
  font-weight: 600;
  color: #4c4c4c;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 151px;
  overflow: hidden;
  margin: 0;
}
.cd-data div.image {
  height: 151px;
  width: 151px;
}
.cd-data p.artist {
  bottom: 5px;
  font-size: 13px;
  color: var(--color-title);
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 151px;
  overflow: hidden;
  margin: 0;
}

.cd-data .dash-badget {
  position: relative;
  height: 20px;
  top: 10px;
  right: -10px;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
}
.songs-list {
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
  list-style-type: decimal;
  padding-left: 25px;
}
.delete-draft {
  position: absolute;
  top: -5px;
  right: -5px;
  cursor: pointer;
  filter: drop-shadow(1px 1px 1px #97979780);
  border-radius: 50px;
}
.delete-draft:hover {
  filter: brightness(1.1);
}
.view-all {
  margin-right: 16px;
  position: absolute;
  right: 0px;
  top: 5px;
  min-height: 26px;
  text-transform: capitalize;
  font-size: 13px;
  height: 26px;
}
/********* Medias *********/
@media (max-width: 400px) {
  .songs-list {
    columns: 1;
    -webkit-columns: 1;
    -moz-columns: 1;
  }
}
@media (max-width: 420px) {
  .counts-cards .ant-card {
    min-height: 119px;
  }
}
@media (max-width: 489px) {
  .container-box.dashboard {
    margin-top: 10px;
    padding: 20px 20px 90px 20px;
  }
  .container-admin {
    padding: 20px 20px 80px 20px;
  }
  .user-element h1.title {
    font-size: 21px;
  }
}
@media (max-width: 270px) {
  .list-rel .add-rel {
    padding: 22px;
  }
  .list-rel .add-rel,
  .list-rel .empty-rel,
  .list-cd .cd-data img, .list-cd .cd-data .avatar-list {
    width: 75px !important;
    height: 75px !important;
  }
  .list-rel .no-release-description {
    font-size: 10px;
  }
  .cd-data div.image {
    height: 75px;
  }
  .list-cd .cd-data,
  .cd-data p.album,
  .cd-data div.image,
  .cd-data p.artist {
    width: 75px;
  }
  .list-rel .empty-rel.ant-empty-normal .ant-empty-description {
    width: 80px;
  }
}
