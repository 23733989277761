.bar-progress{
    position: absolute;
    width: 100%;
    top: 0px;
    left: 0;
    background: #E9E9E9;
    height: 6px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
.bar-progress .bar{
    width: 20%;
    position: relative;
    bottom: 9px;
    display: inline-block;
    height: 6px;
}
.bar-progress .bar.left{
    border-top-left-radius: 10px;
}
.bar-progress .bar.right{
    border-top-right-radius: 10px;
}
@keyframes fillLeft {
    0% { width: 0; }
    100% { width: 20%; }
}
.filled{
    background-color: var(--outrageouse-orange);
    animation: fillLeft 1.5s ease-in-out;
    animation-fill-mode:both;
}
@keyframes emptyBar {
    0% { width: 25%; }
    100% { width: 0; }
}
.empty-bar{
    background-color: var(--outrageouse-orange);
    animation: emptyBar 1.5s ease-in-out;
    animation-fill-mode:both;
}
/********* Create Bar *********/
.stepper-container {
  position: relative;
  width: 100%;
  margin: 0;
  border-radius: 12px 12px 0 0;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.bar-text{
  position: relative;
  left: 0;
  top: 0;
  width: 100%;
  height: 40px;
  display: flex;
  color: #9B9B9BE5;
  background: #2C2C2C;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  transition: all 0.3s ease;
  overflow: hidden;
  align-items: center;
}

.bar-text .bar{
  position: relative;
  width: 14.28%;
  cursor: pointer;
  padding: 5px 0;
  font-weight: bold;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  transition: all 0.3s ease;
  z-index: 2;
  height: 100%;
}

.bar-text .bar.left{
  border-top-left-radius: 12px;
}

.bar-text .bar.right{
  border-top-right-radius: 12px;
  cursor: default;
  z-index: 2;
}

.bar-text .bar.completed {
  color: #ffffff;
  background-color: rgba(255, 111, 67, 0.85);
  z-index: 2;
}

.bar-text .bar.active {
  color: #ffffff;
  background-color: #ff6f43;
  z-index: 2;
  position: relative;
}

.bar-text .bar.active:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 40%;
  height: 3px;
  background-color: #ffffff;
  border-radius: 3px 3px 0 0;
}

.bar-text .bar.active .step-number {
  background-color: rgba(255, 255, 255, 0.3);
  border-color: #ffffff;
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.2);
}

.bar-text .bar:not(:last-child):after {
  content: '';
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 1px;
  height: 20px;
  background-color: rgba(255, 255, 255, 0.2);
}

.bar-text .bar .step-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 0 5px;
  height: 100%;
}

.bar-text .bar .step-number {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.15);
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  border: 2px solid transparent;
  transition: all 0.3s ease;
}

.bar-text .bar .step-label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  letter-spacing: 0.3px;
}

.bar-text .bar.completed .step-number {
  background-color: rgba(255, 255, 255, 0.25);
  border-color: rgba(255, 255, 255, 0.6);
}

.bar-text .bar.left.last-step{
  border-top-left-radius: 12px;
}

.topBar{
  position: absolute;
  z-index: 1;
  top: 0;
  width: 0;
  height: 60px;
  border-radius: 12px 0 0 0;
  background-color: #ff6f43;
  transition: width 0.8s cubic-bezier(0.22, 1, 0.36, 1);
  box-shadow: 0 0 15px rgba(255, 111, 67, 0.5);
}

/* Mobile Stepper Styles */
.stepper-mobile-indicator {
  display: none;
  width: 100%;
  padding: 12px 0;
  background-color: #3C3C3C;
  color: #ffffff;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  cursor: pointer;
  flex-direction: column;
  position: relative;
  z-index: 10;
}

/* Add internal padding to the header content */
.stepper-mobile-indicator .stepper-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;
  padding: 4px 30px;
  cursor: pointer;
}

/* Add a subtle indication that the header is tappable */
.stepper-mobile-indicator .stepper-header:active {
  background-color: rgba(255, 255, 255, 0.05);
}

.stepper-mobile-indicator .current-step {
  font-weight: bold;
  font-size: 16px;
  color: #ff6f43;
  transition: color 0.3s ease;
}

.stepper-mobile-indicator .step-counter {
  background-color: rgba(255, 111, 67, 0.2);
  border-radius: 16px;
  padding: 4px 8px;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.stepper-mobile-indicator .expand-icon {
  font-size: 12px;
  transition: transform 0.3s ease;
  margin-left: 8px;
  opacity: 0.8;
}

.stepper-mobile-indicator .expand-icon.expanded {
  transform: rotate(180deg);
}

/* Keep internal padding only for the mini stepper */
.mini-stepper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 16px;
  position: relative;
  padding: 4px 38px; /* Increase padding to prevent edge elements from getting cut off */
  box-sizing: border-box;
}

/* Adjust mini progress bar to account for padding */
.mini-progress-bar {
  position: absolute;
  height: 3px;
  background-color: rgba(255, 255, 255, 0.2);
  width: calc(100% - 92px); /* 38px padding on each side + 16px original value */
  top: 50%;
  left: 46px; /* 38px padding + 8px original value */
  z-index: 1;
}

.mini-progress-fill {
  height: 100%;
  background-color: #ff6f43;
  transition: width 0.6s cubic-bezier(0.25, 0.1, 0.25, 1);
  transform-origin: left center;
}

.mini-step {
  position: relative;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: #3C3C3C;
  border: 2px solid rgba(255, 255, 255, 0.3);
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.4s cubic-bezier(0.25, 0.1, 0.25, 1);
  cursor: pointer;
  margin: 0 -4px; /* Negative margin to ensure progress bar reaches all dots */
  flex-shrink: 0; /* Prevent circles from shrinking */
  box-sizing: border-box; /* Include border in sizing */
  padding: 0; /* Remove any padding */
}

.mini-step:first-child {
  margin-left: 0;
}

.mini-step:last-child {
  margin-right: 0;
}

/* Completed step */
.mini-step.completed {
  background-color: #ff6f43;
  border-color: #ff6f43;
}

/* Active step - currently visible */
.mini-step.active {
  background-color: #ff6f43;
  border-color: white;
  transform: scale(1.3);
  box-shadow: 0 0 0 3px rgba(255, 111, 67, 0.3);
}

/* Future accessible steps - can be navigated to */
.mini-step:not(.completed):not(.active) {
  background-color: #4c4c4c;
  border-color: rgba(255, 255, 255, 0.3);
}

/* Steps that cannot be navigated to yet */
.mini-step:not(.completed):not(.active)[disabled] {
  opacity: 1;
  cursor: not-allowed;
}

.mini-step-number {
  font-size: 12px;
  font-weight: bold;
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  padding: 0;
  line-height: 1;
  text-align: center;
}

/* Media Queries */
@media (min-width: 769px) {
  .stepper-mobile-indicator {
    display: none;
  }
}

@media (max-width: 768px) {
  .stepper-container {
    overflow: hidden;
    margin-bottom: 0;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  }
  
  .stepper-mobile-indicator {
    display: flex;
    background-color: #3C3C3C;
  }
  
  .bar-text {
    max-height: 0;
    opacity: 0;
    pointer-events: none;
    border-radius: 0;
    flex-direction: column;
    height: auto;
    transition: all 0.3s ease-in-out;
  }
  
  .bar-text.expanded {
    max-height: 500px;
    opacity: 1;
    pointer-events: all;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .bar-text .bar {
    width: 100%;
    padding: 12px 30px;
    text-align: left;
    border-radius: 0 !important;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  
  .bar-text .bar:last-child {
    border-bottom: none;
  }
  
  .bar-text .bar.active {
    order: -1;
    background-color: #ff6f43;
  }
  
  .bar-text .bar.completed .step-number {
    background-color: #ff6f43;
    color: white;
  }
  
  .bar-text .bar .step-content {
    justify-content: flex-start;
  }
  
  /* Additional centering help for mobile dropdown */
  .bar-text .bar .step-number {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  
  .topBar {
    display: none;
  }

  .stepper-container.expanded {
    position: relative;
    z-index: 100;
  }
  
  .stepper-container.expanded .mini-stepper {
    display: none;
  }
}

@media (max-width: 480px) {
  .stepper-mobile-indicator .current-step {
    max-width: 170px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .mini-stepper {
    padding: 8px 30px; /* Ensure consistent padding on small screens */
  }
  
  .mini-progress-bar {
    width: calc(100% - 76px); /* Adjust for the smaller padding on mobile */
    left: 38px;
  }
  
  .mini-step {
    width: 32px;
    height: 32px;
    touch-action: manipulation; /* Improve touch interaction */
  }
  
  .mini-step-number {
    font-size: 12px;
  }
  
  /* Remove tooltips functionality */
  .mini-step:before {
    display: none; /* Hide the tooltip completely */
  }
}

/* iOS Safari specific fixes */
@supports (-webkit-touch-callout: none) {
  .stepper-container {
    /* Apply additional styling for iOS Safari */
    -webkit-overflow-scrolling: touch;
  }
  
  .mini-stepper {
    padding: 4px 20px; /* Reduced padding for iOS */
    width: calc(100% - 8px);
    margin-left: 4px;
    margin-right: 4px;
    justify-content: space-between; /* Use consistent spacing method */
  }
  
  .mini-progress-bar {
    width: calc(100% - 60px); /* Adjusted for iOS */
    left: 30px;
    right: 30px;
  }
  
  .mini-step {
    /* Fix for iOS Safari */
    margin: 0;
    -webkit-backface-visibility: hidden;
    will-change: transform;
    padding: 0; /* Remove any potential padding */
  }
  
  .mini-step.active {
    /* Fix scale transform for iOS */
    transform: scale(1.3);
  }
  
  /* Add specific iOS fixes for step numbers in the dropdown menu */
  .bar-text .bar .step-number {
    -webkit-backface-visibility: hidden;
    will-change: transform;
  }
  
  /* Further enhance number centering for iOS Safari */
  .mini-step-number {
    /* Make sure the transform has hardware acceleration */
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    will-change: transform;
  }
}

/* Additional iOS & Mobile Optimizations */
@media (max-width: 380px) {
  /* Very small screens need special handling */
  .mini-stepper {
    padding: 8px 12px;
  }
  
  .mini-step {
    width: 30px;
    height: 30px;
  }
  
  .mini-step-number {
    font-size: 11px;
  }
  
  @supports (-webkit-touch-callout: none) {
    /* Extra adjustments for very small iOS screens */
    .mini-stepper {
      padding: 8px 10px;
    }
    
    .mini-progress-bar {
      width: calc(100% - 40px);
      left: 20px;
    }
  }
}

/********* Filling Orange *********/
@keyframes colorWhite {
    100%{
        z-index: 2;
        color: #fff
    }
}

/* Preserve stepFilled classes for compatibility with the JS code */
.stepFilled {
    width: 12.5%;
    transition: width 0.8s cubic-bezier(0.22, 1, 0.36, 1);
}

.stepFilled1 {
    width: 25%;
    transition: width 0.8s cubic-bezier(0.22, 1, 0.36, 1);
}

.stepFilled2{
    width: 37.5%;
    transition: width 0.8s cubic-bezier(0.22, 1, 0.36, 1);
}

.stepFilled3{
    width: 50%;
    transition: width 0.8s cubic-bezier(0.22, 1, 0.36, 1);
}

.stepFilled4{
    width: 62.5%;
    transition: width 0.8s cubic-bezier(0.22, 1, 0.36, 1);
}

.stepFilled5{
    width: 75%;
    transition: width 0.8s cubic-bezier(0.22, 1, 0.36, 1);
}

.stepFilled6{
    width: 87.5%;
    transition: width 0.8s cubic-bezier(0.22, 1, 0.36, 1);
}

.stepFilled7{
    width: 100%;
    transition: width 0.8s cubic-bezier(0.22, 1, 0.36, 1);
    animation: cleanBar 0.3s forwards;
}

@keyframes cleanBar {
    100%{
        box-shadow: none;
        border-radius: 12px 12px 0px 0px;
    }
}
.fillStep{
    animation: colorWhite 0.3s forwards;
}

/* Maintain original behavior on desktop */
@media (min-width: 769px) {
  /* Ensure orangeBar appearance matches original design on desktop */
  .topBar {
    border-radius: 12px 0 0 0; /* Only rounded on top-left corner */
  }
  
  /* Add a subtle depth effect to the stepper */
  .stepper-container:before {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 5%;
    width: 90%;
    height: 2px;
    border-radius: 50%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    z-index: -1;
  }
}

/********** User Menu **********/
.drop-user .ant-dropdown-menu-item:hover{
  background-color: #f5f5f5;
}
.ant-dropdown-menu-item.ant-dropdown-menu-item-active{
  background-color: #0000;
}
.drop-user ul li.ant-dropdown-menu-item{
  padding: 7px 12px 3px;
}
/********** Medias **********/
/* @media(max-width: 910px){
    .bar-text .bar div {
        margin: auto;
        line-height: 2;
        max-width: 45px;
        font-size: 12px;
        overflow: hidden;
        text-align: center;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
} */
@media(max-width: 745px) and (min-width: 551px){
  .bar-text .bar{
    width: 13%;
  }
  .bar-text .bar div {
    max-width: 37px;
    font-size: 11px;
  }
}
@media(max-width: 400px){
    .bar-text .bar div {
        margin: auto;
        line-height: 2;
        max-width: 35px;
        font-size: 10px;
        overflow: hidden;
        text-align: center;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}

/* Focus styles for keyboard navigation */
.bar-text .bar:focus {
  outline: none;
  box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.5);
}

/* Step status indicators */
.step-status-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.step-status-active {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #ffffff;
  z-index: 2;
}

/* Styling for pending steps */
.bar-text .bar.pending {
  color: rgba(255, 255, 255, 0.6);
}

.bar-text .bar.pending .step-number {
  background-color: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.2);
}

/* Hide the step number when completed (checkmark is shown instead) */
.bar-text .bar.completed .step-number .number {
  opacity: 0;
}

/* Hide the number when active status indicator is shown */
.bar-text .bar.active .step-number .number {
  opacity: 0;
}

/* Styles for disabled steps */
.bar-text .bar[disabled] {
  opacity: 0.7;
  cursor: not-allowed;
}

/* Animation for the active step indicator */
@keyframes pulse {
  0% { transform: scale(1); opacity: 1; }
  50% { transform: scale(1.2); opacity: 0.8; }
  100% { transform: scale(1); opacity: 1; }
}

.bar-text .bar.active .step-status-active {
  animation: pulse 2s infinite ease-in-out;
}

/* Additional responsive adjustments for mobile */
@media (max-width: 768px) {
  .bar-text {
    height: 48px;
  }
  
  .topBar {
    height: 48px;
  }
  
  .bar-text .bar .step-number {
    width: 24px;
    height: 24px;
  }
  
  .bar-text .bar.active:after {
    width: 30%;
  }
  
  .step-status-icon {
    width: 10px;
    height: 10px;
  }
  
  .step-status-active {
    width: 4px;
    height: 4px;
  }
}

/* Add focus styles for keyboard navigation */
.bar-text .bar:focus-visible {
  outline: none;
  box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.7);
}

/* Desktop step hover interactions */
@media (min-width: 769px) {
  .bar-text .bar:not(.active):not(.right):not([disabled]):hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
  
  .bar-text .bar:not(.active):not(.right):not([disabled]):hover .step-number {
    background-color: rgba(255, 255, 255, 0.25);
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1);
  }
  
  .bar-text .bar.completed:hover .step-number {
    transform: scale(1.05);
  }
}

/* Mini step status indicators */
.mini-status-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.mini-status-active {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #ffffff;
  z-index: 2;
}

/* Hide number when icons are displayed in mini steps */
.mini-step.completed .mini-step-number .number,
.mini-step.active .mini-step-number .number {
  opacity: 0;
}

.mini-step-number {
  font-size: 12px;
  font-weight: bold;
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  padding: 0;
  line-height: 1;
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
