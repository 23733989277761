.general-btn {
    position: relative;
    color: #fff;
    border: none;
    cursor: pointer;
    min-height: 40px;
    font-weight: 600;
    margin: 10px 15px 10px auto;
    border-radius: 5px;
    min-width: max-content;
    text-transform: uppercase;
    background: linear-gradient(135deg, var(--outrageouse-orange), #ff4500, coral);
    box-shadow: 0 4px 14px 0 #ff5f2e63;
    margin-right: 15px;
    overflow: hidden;
    transition: background 0.3s ease, box-shadow 0.3s ease, color 0.3s ease;
}

.general-btn::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 300%;
    height: 300%;
    background: radial-gradient(circle, rgba(255,255,255,0.3) 10%, transparent 10.01%);
    background-size: 10px 10px;
    pointer-events: none;
    transform: translate(-50%, -50%) scale(0);
    transition: transform 0.5s ease;
    border-radius: 50%;
}

.general-btn:hover::before,.assign-upc:hover::before {
    transform: translate(-50%, -50%) scale(1);
}

.general-btn:hover,
.general-btn:focus,
.general-btn:active, .assign-upc:hover,
.assign-upc:focus {
    color: #fff;
    background: linear-gradient(135deg, coral, #ff4500, var(--outrageouse-orange));
    box-shadow: 0 6px 20px 0 rgba(255, 95, 46, 0.45);
    opacity: 0.9;
}


.back-btn,
.back-btn:hover,
.back-btn:focus,
.back-btn:active {
    color: #000;
    opacity: 0.6;
    cursor: pointer;
    background-color: transparent !important;
    background: transparent !important;
    box-shadow: none !important;
}