.label-cta-banner {
  background: linear-gradient(135deg, #FF6B4A, #ff9c7d, #ffbe9d, #ff78c8, #9d93ff, #93d0ff);
  border-radius: 20px;
  padding: 40px;
  margin-top: 60px;
  margin-bottom: 20px;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  box-shadow: 0 15px 40px rgba(255, 107, 74, 0.2);
  position: relative;
  overflow: hidden;
}

.label-cta-banner::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle at top right, rgba(255, 255, 255, 0.2), transparent 60%);
  z-index: 0;
}

.label-cta-content {
  max-width: 70%;
  position: relative;
  z-index: 1;
}

.label-cta-title {
  font-size: 32px;
  font-weight: 700;
  margin: 0 0 12px;
  color: white;
  line-height: 1.2;
}

.label-cta-text {
  font-size: 18px;
  opacity: 0.95;
  margin: 0;
  color: white;
  line-height: 1.6;
}

.label-cta-btn {
  background: white;
  color: #333;
  font-weight: 700;
  padding: 14px 28px;
  border-radius: 10px;
  border: none;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
  transition: all 0.3s ease;
  position: relative;
  z-index: 1;
  font-size: 16px;
  letter-spacing: 0.3px;
}

.lets-talk-btn {
  background: white !important;
  color: #333 !important;
  font-weight: 600 !important;
  padding: 12px 30px !important;
  border-radius: 12px !important;
  border: none !important;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1) !important;
  transition: all 0.3s ease !important;
  position: relative !important;
  z-index: 1 !important;
  font-size: 15px !important;
  letter-spacing: 0.5px !important;
  text-transform: uppercase !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  min-width: 150px !important;
  text-align: center !important;
  height: 44px !important;
}

.lets-talk-btn:hover {
  transform: translateY(-2px) !important;
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.15) !important;
  background: #ffffff !important;
}

.label-cta-btn:hover {
  transform: translateY(-3px);
  box-shadow: 0 12px 20px rgba(0, 0, 0, 0.25);
  background: #ffffff;
}

@media (max-width: 768px) {
  .label-cta-banner {
    flex-direction: column;
    text-align: center;
    gap: 28px;
    padding: 30px;
  }
  
  .label-cta-content {
    max-width: 100%;
  }
  
  .label-cta-title {
    font-size: 28px;
  }
  
  .label-cta-text {
    font-size: 16px;
  }
  
  .label-cta-btn, 
  .lets-talk-btn {
    width: 100%;
    max-width: 240px;
    margin: 0 auto !important;
    justify-content: center !important;
  }
} 