.plan-table-container {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  overflow-x: auto;
  scrollbar-width: thin;
  scrollbar-color: #FF6B4A rgba(255, 107, 74, 0.1);
  position: relative;
  padding-bottom: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
}

/* Sticky section header styling - removing sticky positioning */
.sticky-table-header {
  background: white;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.06);
}

.plan-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  font-size: 15px;
  color: #333;
  margin: 0 auto;
  table-layout: fixed;
  box-shadow: none;
  background: white;
  border-radius: 12px;
  overflow: hidden;
}

.plan-table th,
.plan-table td {
  text-align: center;
  padding: 16px 20px;
  border-bottom: 1px solid #f0f0f0;
  vertical-align: middle;
  line-height: 1.5;
  transition: all 0.2s ease;
}

.plan-table tbody tr:hover {
  background-color: rgba(245, 245, 250, 0.5);
}

/* Section separators */
.plan-table tr.section-header td {
  padding: 20px 24px 10px;
  background-color: #f9f9fb;
  border-bottom: none;
}

.section-header .section-title {
  color: #1a1a1a;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: -0.3px;
  text-transform: uppercase;
  opacity: 0.8;
}

.feature-column {
  text-align: left;
  width: 30%;
  min-width: 220px;
  font-weight: 600;
  color: #333;
  background-color: #fafafa;
  position: sticky;
  left: 0;
  z-index: 10;
  box-shadow: 4px 0 6px rgba(0, 0, 0, 0.03);
}

.feature-info {
  display: inline-flex;
  align-items: center;
  position: relative;
  margin-left: 4px;
  cursor: pointer;
}

.feature-info .info-icon {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #e0e0e0;
  color: #666;
  font-size: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
}

.feature-info .info-tooltip {
  position: absolute;
  bottom: calc(100% + 10px);
  left: 50%;
  transform: translateX(-50%);
  width: 200px;
  padding: 12px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  font-size: 13px;
  line-height: 1.5;
  color: #333;
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s ease;
  pointer-events: none;
  z-index: 100;
}

.feature-info:hover .info-tooltip {
  visibility: visible;
  opacity: 1;
}

.plan-column {
  width: 26.66%; /* More space for plan columns */
  min-width: 220px;
  text-align: center;
  background: transparent;
  transition: all 0.4s ease;
  border-left: none;
  position: relative;
}

.plan-column.core-plan {
  border-top: 4px solid #5B8FF9;
}

.plan-column.extra-plan {
  border-top: 4px solid #A277FF;
}

.plan-column.pro-plan {
  border-top: 4px solid #5AD8A6;
}

.plan-column.selected-plan {
  background: rgba(255, 249, 247, 0.4);
  position: relative;
  box-shadow: 0 0 0 1px rgba(255, 107, 74, 0.1);
  z-index: 1;
  transform: none;
}

.plan-header {
  padding: 30px 16px 20px;
  position: relative;
  background: #ffffff;
  border-radius: 20px 20px 0 0;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.05);
  margin-bottom: -1px;
  overflow: visible !important;
  transition: all 0.3s ease;
}

.plan-header:hover {
  transform: translateY(-4px);
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.08);
}

.plan-header.core-header {
  border-top: 4px solid #5B8FF9;
}

.plan-header.extra-header {
  border-top: 4px solid #A277FF;
}

.plan-header.pro-header {
  border-top: 4px solid #5AD8A6;
}

.plan-header h3 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #1a1a1a;
  font-weight: 700;
  position: relative;
  display: inline-block;
}

.plan-header h3::after {
  content: '';
  position: absolute;
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 3px;
  background: #FF6B4A;
  border-radius: 2px;
}

.plan-table-container .price {
  font-size: 38px;
  font-weight: bold;
  color: #1a1a1a;
  margin: 20px 0 10px;
  display: flex;
  align-items: baseline;
  justify-content: center;
}

.plan-table-container .currency {
  font-size: 24px;
  margin-right: 4px;
  font-weight: 600;
}

.plan-table-container .amount {
  font-size: 38px;
}

.plan-table-container .period {
  font-size: 15px;
  color: #666;
  margin-left: 6px;
  font-weight: 400;
}

.plan-table-container .subtitle {
  font-size: 16px;
  color: #1a1a1a;
  margin: 16px 0 8px;
  font-weight: 600;
}

.plan-table-container .description {
  font-size: 14px;
  color: #666;
  margin-bottom: 16px;
  line-height: 1.5;
}

.plan-table-container .category-row {
  background-color: #f8f8fa;
}

.plan-table-container .category-name {
  text-align: left;
  font-weight: 700;
  color: #333;
  font-size: 16px;
  padding: 16px 20px;
  background: linear-gradient(90deg, #f5f5f7, #fafafa);
  letter-spacing: 0.3px;
  border-bottom: 1px solid #e8e8e8;
  position: relative;
}

.plan-table-container .category-name::after {
  content: "";
  position: absolute;
  left: 0;
  top: 16px;
  bottom: 16px;
  width: 4px;
  background: linear-gradient(180deg, #FF6B4A, #FF825F);
  border-radius: 0 2px 2px 0;
}

.plan-table-container .feature-name {
  text-align: left;
  padding-left: 20px;
  font-weight: 500;
  color: #444;
  font-size: 14px;
  position: relative;
}

.plan-table-container .feature-check {
  font-weight: 500;
  text-align: center;
  color: #666;
  font-size: 15px;
}

.plan-table-container .feature-check.selected-plan {
  background-color: rgba(255, 107, 74, 0.04);
}

.plan-table-container .check-icon {
  color: #3ecf8e;
  font-size: 18px;
  display: inline-flex;
  margin: 0 auto;
  transition: all 0.2s ease;
}

.plan-table-container .feature-check:hover .check-icon {
  transform: scale(1.15);
}

.plan-table-container .cross-icon {
  color: #999;
  font-size: 14px;
  display: inline-flex;
  margin: 0 auto;
  opacity: 0.7;
}

.plan-table-container .dash-icon {
  color: #ccc;
  font-size: 20px;
  display: inline-flex;
  margin: 0 auto;
  line-height: 0;
  font-weight: 300;
}

.plan-table-container .plan-btn {
  margin-top: 16px;
  transition: all 0.3s ease;
  background: linear-gradient(90deg, #FF6B4A 0%, #FF825F 100%);
  border: none;
  border-radius: 50px;
  color: white;
  box-shadow: 0 4px 12px rgba(255, 107, 74, 0.2);
  height: 44px;
  font-weight: 600;
  font-size: 15px;
  letter-spacing: 0.5px;
  width: 85%;
  margin-left: auto;
  margin-right: auto;
  padding: 0 24px;
}

.plan-table-container .plan-btn:hover {
  background: linear-gradient(90deg, #FF825F 0%, #FF9470 100%);
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(255, 107, 74, 0.25);
}

.plan-table-container .plan-btn.selected {
  background: linear-gradient(90deg, #FF825F 0%, #FF9470 100%);
  box-shadow: 0 6px 16px rgba(255, 107, 74, 0.25);
}

/* Popular plan highlight - more prominent styling */
.plan-column.popular-plan {
  position: relative;
  transform: none;
  z-index: 2;
  overflow: visible !important; /* Ensure badge isn't clipped */
}

.plan-column.popular-plan .plan-header {
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.04);
  background: #fff;
  border: none;
  padding-top: 24px;
  border-top: 3px solid #FF6B4A;
  margin-top: 15px;
  overflow: visible !important;
  border-radius: 20px 20px 0 0; /* Match the border-radius with plan cards */
}

/* Fix for the Popular badge */
.popular-plan-badge {
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  background: #FF6B4A;
  color: white;
  font-size: 12px;
  font-weight: 700;
  padding: 4px 10px;
  border-radius: 20px;
  box-shadow: 0 4px 10px rgba(255, 107, 74, 0.2);
  white-space: nowrap;
  z-index: 1;
}

/* Show table on desktop */
@media (min-width: 1024px) {
  .plan-table-container {
    display: block;
  }
}

/* Price savings */
.price-savings {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 0 12px;
}

.original-price {
  text-decoration: line-through;
  color: #999;
}

.save-text {
  font-size: 14px;
  font-weight: 600;
  color: #52c41a;
  background: rgba(82, 196, 26, 0.1);
  padding: 4px 12px;
  border-radius: 12px;
}

.plan-table-container .feature-value {
  font-weight: 500;
  color: #333;
}

.plan-table-container .feature-row:hover {
  background-color: rgba(245, 245, 247, 0.5);
}

/* Improve spacing between sections */
.plan-table-container .category-row + .feature-row td {
  padding-top: 16px;
}

/* Make highlighted features stand out more */
.plan-table-container .feature-check.selected-plan .feature-value {
  font-weight: 600;
  color: #1a1a1a;
}

/* Highlight important values in the table */
.plan-table-container .feature-check .feature-value:has-text("100%"),
.plan-table-container .feature-check .feature-value:has-text("Up to 10"),
.plan-table-container .feature-check .feature-value:has-text("10%"),
.plan-table-container .feature-check .feature-value:has-text("24H") {
  font-weight: 600;
  color: #ff6b4a;
}

/* Add hover effect to plan columns */
.plan-table-container .plan-column:hover:not(.selected-plan) {
  background-color: transparent;
}

/* Alternate row styling for better readability */
.feature-row:nth-child(even) td {
  background-color: rgba(250, 251, 252, 0.7);
}

.feature-row:nth-child(even) td.selected-plan {
  background-color: rgba(255, 249, 247, 0.7);
}

/* Monthly price section styling */
.price-row {
  background: #f8f9fa;
  padding: 0 !important;
  border-bottom: none !important;
}

.price-comparison {
  padding: 12px 24px;
}

.monthly-label {
  font-weight: 600;
  color: #1a1a1a;
  font-size: 15px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

.bold-feature {
  font-weight: 700;
  color: #1a1a1a;
}

.price-value {
  font-size: 18px;
  font-weight: 700;
  color: #1a1a1a;
}

.price-amount {
  font-size: 22px;
  color: #ff6b4a;
}

.price-period {
  font-size: 15px;
  color: #666;
  margin-left: 2px;
}

/* Make sure the container is full width on larger screens */
@media (min-width: 1200px) {
  .plan-table-container {
    padding: 0;
    max-width: 100%;
    margin: 0;
  }
  
  .plan-table {
    width: 100%;
    max-width: 100%;
    margin: 0;
  }
  
  .plan-column {
    min-width: 220px;
  }
  
  .price-amount {
    font-size: 24px;
  }
}

/* Adjustments for the extra-wide screens */
@media (min-width: 1600px) {
  .plan-table-container {
    max-width: 100%;
    padding: 0;
    margin: 0;
  }
  
  .plan-table {
    max-width: 100%;
    margin: 0;
  }
  
  .feature-column {
    width: 18%;
  }
}

.plan-table td.feature-cell {
  padding: 8px 12px;
  text-align: center;
  font-size: 14px;
  position: relative;
}

.plan-table .feature-cell .checkmark {
  color: #5AD8A6;
  font-size: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  transition: all 0.2s ease;
}

.plan-table .feature-cell .cross {
  color: #e0e0e0;
  font-size: 18px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  opacity: 0.7;
  transition: all 0.2s ease;
}

.plan-table td.feature-cell.highlight {
  background-color: rgba(255, 249, 247, 0.4);
}

/* Feature Tags */
.feature-tag {
  background: rgba(90, 216, 166, 0.1);
  color: #5AD8A6;
  padding: 4px 10px;
  border-radius: 50px;
  font-size: 13px;
  font-weight: 600;
  display: inline-block;
  white-space: nowrap;
}

.feature-tag.premium {
  background: rgba(255, 107, 74, 0.1);
  color: #FF6B4A;
}

.feature-tag.limited {
  background: rgba(255, 193, 7, 0.1);
  color: #FFC107;
}

/* Animate the checkmark on hover */
.plan-table tbody tr:hover .feature-cell .checkmark {
  transform: scale(1.15);
}

/* Section title styling */
.plan-table-section {
  margin-top: 40px;
  position: relative;
}

.plan-table-section-title {
  font-size: 24px;
  font-weight: 700;
  color: #1a1a1a;
  margin-bottom: 20px;
  text-align: left;
  position: relative;
  padding-left: 16px;
}

.plan-table-section-title::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 4px;
  height: 24px;
  background: #FF6B4A;
  border-radius: 4px;
}

/* Table header row - removing sticky positioning */
.plan-table thead {
  background: #fff;
  overflow: visible !important; /* Keep overflow visible for the badge */
}

.plan-table thead tr {
  overflow: visible !important; /* Keep overflow visible for the badge */
}

.plan-table thead th {
  overflow: visible !important; /* Keep overflow visible for the badge */
  background-color: #ffffff;
  border-bottom: 2px solid #f0f0f0;
  padding-top: 20px;
  padding-bottom: 20px;
}

.plan-name {
  font-size: 24px;
  font-weight: 800;
  letter-spacing: -0.5px;
  margin-bottom: 10px;
  display: block;
}

.plan-price {
  font-size: 36px;
  font-weight: 800;
  color: #1a1a1a;
  margin-bottom: 4px;
  line-height: 1.1;
  display: flex;
  justify-content: center;
  align-items: baseline;
}

.plan-price .currency {
  font-size: 20px;
  margin-right: 3px;
  font-weight: 600;
  opacity: 0.9;
}

.plan-price .amount {
  font-size: 36px;
}

.plan-period {
  font-size: 14px;
  color: #666;
  display: block;
  margin-bottom: 16px;
  opacity: 0.8;
}

.plan-cta {
  background: linear-gradient(90deg, #FF6B4A 0%, #FF825F 100%);
  color: white;
  border: none;
  border-radius: 50px;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.5px;
  padding: 12px 24px;
  display: inline-block;
  margin-top: 16px;
  transition: all 0.2s ease;
  cursor: pointer;
  box-shadow: 0 8px 20px rgba(255, 107, 74, 0.2);
  text-decoration: none;
}

.plan-cta:hover {
  transform: translateY(-2px);
  box-shadow: 0 10px 25px rgba(255, 107, 74, 0.3);
}

/* Plan column specific colors */
.plan-column.core-plan .plan-name {
  color: #5B8FF9;
}

.plan-column.extra-plan .plan-name {
  color: #A277FF;
}

.plan-column.pro-plan .plan-name {
  color: #5AD8A6;
}

/* Tooltip for features */
.feature-tooltip {
  position: relative;
  cursor: help;
}

.feature-tooltip-content {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background: white;
  border-radius: 8px;
  padding: 12px 16px;
  width: 240px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
  font-size: 13px;
  line-height: 1.5;
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s ease;
  pointer-events: none;
}

.feature-tooltip:hover .feature-tooltip-content {
  opacity: 1;
  visibility: visible;
  bottom: calc(100% + 10px);
}

/* Make table rows more compact */
.plan-table tbody tr td {
  padding: 8px 24px;
}

/* Update the popular badge to ensure it's visible during scroll */
.popular-plan-badge {
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  background: #FF6B4A;
  color: white;
  font-size: 12px;
  font-weight: 700;
  padding: 4px 10px;
  border-radius: 20px;
  box-shadow: 0 4px 10px rgba(255, 107, 74, 0.2);
  white-space: nowrap;
  z-index: 1;
}

/* Ensure the popular plan column has overflow visible */
.plan-column.popular-plan {
  position: relative;
  transform: none;
  z-index: 2;
  overflow: visible !important; /* Ensure badge isn't clipped */
}

/* Ensure the select buttons in the table have consistent styling */
.select-plan-btn {
  background: linear-gradient(90deg, #FF6B4A 0%, #FF825F 100%);
  color: white;
  border: none;
  border-radius: 50px;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.5px;
  padding: 12px 24px;
  width: 80%;
  margin: 20px auto;
  display: block;
  transition: all 0.2s ease;
  cursor: pointer;
  box-shadow: 0 8px 20px rgba(255, 107, 74, 0.2);
}

.plan-column-simple {
  width: calc(70% / 3);
  min-width: 150px;
  border-left: 1px solid #f0f0f0;
  padding: 20px 15px;
  transition: all 0.3s ease;
}

.plan-column-simple.popular-plan {
  background: linear-gradient(180deg, rgba(255, 107, 74, 0.08) 0%, rgba(255, 107, 74, 0.03) 100%);
  position: relative;
  overflow: hidden;
}

.plan-column-simple.selected-plan {
  background-color: rgba(255, 107, 74, 0.04);
  box-shadow: inset 0 3px 0 #FF6B4A;
}

.plan-column-simple .plan-name {
  font-size: 18px;
  font-weight: 700;
  color: #1a1a1a;
  margin: 0 0 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.plan-column-simple .popular-plan-badge {
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  background: #FF6B4A;
  color: white;
  font-size: 12px;
  font-weight: 700;
  padding: 4px 10px;
  border-radius: 20px;
  box-shadow: 0 4px 10px rgba(255, 107, 74, 0.2);
  white-space: nowrap;
  z-index: 1;
}

.plan-column-simple .plan-price {
  margin-bottom: 20px;
}

.plan-column-simple .price {
  font-size: 26px;
  font-weight: 800;
  color: #1a1a1a;
  line-height: 1.2;
}

.plan-column-simple .currency {
  font-size: 18px;
  font-weight: 600;
  vertical-align: super;
  margin-right: 2px;
  position: relative;
  top: 2px;
}

.plan-column-simple .amount {
  font-size: 28px;
  font-weight: 800;
}

.plan-column-simple .period {
  font-size: 14px;
  font-weight: 400;
  color: #666;
  margin-left: 1px;
}

.select-plan-btn {
  display: inline-block;
  margin-top: 16px;
  background: linear-gradient(90deg, #FF6B4A 0%, #FF825F 100%);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 10px rgba(255, 107, 74, 0.2);
}

.select-plan-btn:hover {
  background: linear-gradient(90deg, #FF825F 0%, #FF9470 100%);
  transform: translateY(-2px);
  box-shadow: 0 6px 15px rgba(255, 107, 74, 0.25);
}

/* Mobile-friendly table styles */
@media (max-width: 768px) {
  .plan-table-container {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    padding: 0;
    margin: 0;
    width: 100%;
    display: block !important;
    visibility: visible !important;
    max-width: none;
    position: relative;
  }

  .plan-table {
    min-width: 650px; /* Ensure table doesn't shrink too small */
    margin: 0;
    border-collapse: collapse;
    display: table !important;
    visibility: visible !important;
    transform: none !important;
    opacity: 1 !important;
  }
  
  .feature-column {
    min-width: 150px;
    padding: 10px 8px;
    font-size: 14px;
  }
  
  .plan-column-simple {
    min-width: 150px;
    padding: 10px 8px !important;
  }
  
  .category-name {
    font-size: 16px;
    padding: 12px 8px;
    background-color: #f8f8f8;
  }
  
  .feature-name {
    font-size: 14px;
    padding: 8px;
  }
  
  .feature-check {
    padding: 8px;
    text-align: center;
  }
  
  .plan-column-simple .plan-name {
    font-size: 16px;
  }
  
  .plan-column-simple .popular-plan-badge {
    font-size: 10px;
    padding: 3px 8px;
    top: -8px;
  }
  
  .plan-table tbody tr {
    display: table-row !important;
  }
  
  .plan-table td, 
  .plan-table th {
    display: table-cell !important;
  }
  
  /* Remove scrolling indicator as it might interfere */
  .plan-table-container:after {
    display: none;
  }
} 