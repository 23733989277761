.pricing-card {
  background: #ffffff;
  border-radius: 16px;
  padding: 36px;
  width: 100%;
  max-width: 630px;
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.08);
  transition: all 0.3s ease;
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  border: 1px solid rgba(232, 232, 232, 0.5);
  transform: translateY(0);
}

.pricing-card:hover {
  transform: translateY(-6px);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.12);
}

.pricing-card.selected {
  border: 2px solid #FF6B4A;
  box-shadow: 0 15px 35px rgba(255, 107, 74, 0.15);
}

.pricing-card.current-plan {
  border: 2px solid #52c41a;
  box-shadow: 0 0 15px rgba(82, 196, 26, 0.2);
}

.current-plan-badge {
  position: absolute;
  top: -12px;
  right: 20px;
  background-color: #52c41a;
  color: white;
  font-size: 12px;
  font-weight: bold;
  padding: 6px 12px;
  border-radius: 30px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
  z-index: 1;
}

.card-header {
  margin-bottom: 24px;
  position: relative;
  height: 34px;
  display: flex;
  align-items: center;
}

.plan-name {
  font-size: 26px;
  font-weight: 700;
  color: #1a1a1a;
  margin: 0;
}

.most-popular-badge {
  position: absolute;
  top: -14px;
  right: -14px;
  background: #FF6B4A;
  color: white;
  font-size: 12px;
  font-weight: 700;
  padding: 6px 12px;
  border-radius: 20px;
  box-shadow: 0 6px 14px rgba(255, 107, 74, 0.25);
  letter-spacing: 0.5px;
}

.card-pricing {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}

.price-amount {
  font-size: 56px;
  font-weight: 800;
  color: #1a1a1a;
  line-height: 1.1;
  margin: 0;
  display: flex;
  align-items: flex-start;
  background: linear-gradient(90deg, #1a1a1a, #333);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.currency {
  font-size: 32px;
  margin-right: 4px;
  margin-top: 8px;
}

.price-period {
  font-size: 16px;
  color: #666;
  margin-top: 6px;
  margin-bottom: 16px;
  font-weight: 500;
}

/* Monthly equivalent pricing styles */
.monthly-equivalent {
  background-color: #f5f5f7;
  border-radius: 10px;
  padding: 8px 12px;
  font-size: 14px;
  display: inline-flex;
  align-items: center;
  margin-bottom: 12px;
  width: fit-content;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04);
}

.monthly-equivalent.white-text {
  background-color: rgba(255, 255, 255, 0.25);
  color: white;
  box-shadow: 0 2px 8px rgba(255, 255, 255, 0.1);
}

.equivalent-text {
  font-weight: 500;
}

.original-price {
  text-decoration: line-through;
  color: #999;
  margin-right: 5px;
  font-weight: 500;
  opacity: 0.8;
}

.monthly-equivalent.white-text .original-price {
  color: rgba(255, 255, 255, 0.7);
}

.monthly-price {
  font-weight: 700;
  margin: 0 2px;
  color: #3ecf8e;
  padding-top: 2px;
}

.monthly-equivalent.white-text .monthly-price {
  color: white;
}

.currency-small {
  margin-right: 1px;
}

/* Annual savings styles */
.annual-savings {
  background-color: #ecf9f0;
  border-radius: 8px;
  padding: 6px 10px;
  margin-bottom: 12px;
  font-size: 14px;
  display: inline-flex;
  align-items: center;
  width: fit-content;
}

.annual-savings.extra-savings {
  background-color: rgba(255, 255, 255, 0.2);
  color: white;
}

.savings-label {
  font-size: 13px;
  font-weight: 500;
}

.savings-amount {
  font-weight: 700;
  font-size: 15px;
  margin-left: 2px;
  color: #3ecf8e;
}

.extra-savings .savings-amount {
  color: white;
}

/* Split Rate Specific Styling */
.split-rate-title {
  font-size: 56px;
  font-weight: 800;
  background: linear-gradient(90deg, #FF6B4A, #ff9c7d, #ffbe9d, #ff78c8, #9d93ff, #93d0ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  margin: 0 0 12px;
  line-height: 1.1;
}

.split-rate-badge {
  display: inline-block;
  background: #f5f5f7;
  padding: 8px 14px;
  border-radius: 30px;
  font-size: 14px;
  font-weight: 600;
  color: #333;
  border: 1px solid rgba(0, 0, 0, 0.06);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.06);
  margin-top: 6px;
  width: fit-content;
}

.plan-subtitle {
  font-size: 16px;
  color: #444;
  margin: 0 0 22px;
  line-height: 1.5;
  min-height: 48px;
  display: flex;
  align-items: center;
}

.feature-list {
  margin-top: 10px;
  text-align: left;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-height: 300px;
  justify-content: flex-start;
  gap: 12px;
}

.feature-item {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  font-size: 14px;
  padding: 3px 0;
}

.checkbox-pricing {
  width: 18px;
  height: 18px;
  min-width: 18px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(62, 207, 142, 0.1);
  border-radius: 4px;
  padding: 3px;
}

.green-box {
  /* border: 1.5px solid #3ecf8e; - removed */
}

.white-box {
  background-color: rgba(255, 255, 255, 0.2);
}

.feature-text {
  font-size: 15px;
  color: #333;
  line-height: 1.4;
}

/* Card-specific styling */
.pricing-card.core {
  border: 1px solid #e0e0e0;
}

.pricing-card.extra {
  background: linear-gradient(135deg, #FF6B4A, #ff9c7d, #ffbe9d, #ff78c8, #9d93ff, #93d0ff);
  color: white;
  box-shadow: 0 15px 40px rgba(255, 107, 74, 0.2);
}

.pricing-card.pro {
  border: 1px solid #e0e0e0;
  background: white;
}

.pricing-card.extra .plan-name,
.pricing-card.extra .price-amount,
.pricing-card.extra .feature-text,
.pricing-card.extra .plan-subtitle,
.pricing-card.extra .price-period,
.pricing-card.extra .currency,
.pricing-card.extra .split-rate-badge {
  color: white;
}

.pricing-card.extra .split-rate-badge {
  color: white;
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.5);
}

.pricing-card.extra .feature-item span {
  color: white;
}

.pricing-card.extra .price-amount,
.pricing-card.extra .price-amount .currency {
  background: transparent !important;
  background-image: none !important;
  background-clip: border-box !important;
  -webkit-background-clip: border-box !important;
  -webkit-text-fill-color: white !important;
  color: white !important;
}

.pricing-card.extra .price-period {
  color: white !important;
}

/* Button styling */
.pricing-cta-btn {
  width: 100%;
  margin-top: 30px;
  padding: 14px 20px;
  border: none;
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  letter-spacing: 0.5px;
}

.pricing-card.core .pricing-cta-btn,
.pricing-card.pro .pricing-cta-btn {
  background: linear-gradient(90deg, #FF6B4A 0%, #FF825F 100%);
  color: white;
}

.pricing-card.core .pricing-cta-btn:hover,
.pricing-card.pro .pricing-cta-btn:hover {
  background: linear-gradient(90deg, #FF825F 0%, #FF9470 100%);
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(255, 107, 74, 0.25);
}

.pricing-card.extra .pricing-cta-btn,
.pricing-card.extra button.pricing-cta-btn.extra,
.pricing-card.extra .pricing-cta-btn.pricing-btn,
.pricing-card.extra button.pricing-btn {
  background-color: white !important;
  background: white !important;
  color: #FF6B4A !important;
  border: none !important;
  font-weight: 700 !important;
  box-shadow: 0 4px 8px rgba(255, 255, 255, 0.2) !important;
}

.pricing-card.extra .pricing-cta-btn:hover,
.pricing-card.extra button.pricing-cta-btn.extra:hover,
.pricing-card.extra .pricing-cta-btn.pricing-btn:hover,
.pricing-card.extra button.pricing-btn:hover {
  background-color: rgba(255, 255, 255, 0.9) !important;
  background: rgba(255, 255, 255, 0.9) !important;
  color: #FF6B4A !important;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(255, 255, 255, 0.3) !important;
}

/* Current plan button styling */
.pricing-cta-btn.current-plan-btn {
  background: linear-gradient(90deg, #52c41a 0%, #73d13d 100%) !important;
  color: white !important;
  border: none !important;
}

.pricing-cta-btn.current-plan-btn:hover {
  background: linear-gradient(90deg, #73d13d 0%, #95de64 100%) !important;
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(82, 196, 26, 0.25) !important;
}

/* Special case for extra plan with current plan */
.pricing-card.extra .pricing-cta-btn.current-plan-btn {
  background: linear-gradient(90deg, #52c41a 0%, #73d13d 100%) !important;
  color: white !important;
}

/* Current plan info section */
.current-plan-info {
  background-color: #f6ffed;
  border: 1px solid #b7eb8f;
  border-radius: 10px;
  padding: 16px 24px;
  margin-bottom: 24px;
  text-align: center;
}

.current-plan-notice {
  font-size: 16px;
  margin-bottom: 8px;
  color: #333;
}

.current-plan-notice strong {
  color: #52c41a;
  font-weight: 700;
}

.current-plan-change {
  font-size: 14px;
  color: #666;
  margin: 0;
}

/* View all features link */
.view-all-features {
  text-align: center;
  margin: 30px 0;
}

.view-all-link {
  background: none;
  border: none;
  color: #FF6B4A;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  text-decoration: none;
  padding: 8px 16px;
  transition: all 0.3s ease;
  border-radius: 8px;
}

.view-all-link:hover {
  background: rgba(255, 107, 74, 0.08);
  text-decoration: underline;
}

@media (max-width: 768px) {
  .pricing-card {
    margin-bottom: 30px;
  }
}

/* Bottom label CTA banner */
.label-cta-banner {
  background: linear-gradient(135deg, #FF6B4A, #ff9c7d, #ffbe9d, #ff78c8, #9d93ff, #93d0ff);
  border-radius: 16px;
  padding: 32px;
  margin-top: 48px;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.label-cta-content {
  max-width: 70%;
}

.label-cta-title {
  font-size: 28px;
  font-weight: 700;
  margin: 0 0 8px;
}

.label-cta-text {
  font-size: 16px;
  opacity: 0.9;
  margin: 0;
}

.label-cta-btn {
  background: white;
  color: #333;
  font-weight: 600;
  padding: 12px 24px;
  border-radius: 8px;
  border: none;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .label-cta-banner {
    flex-direction: column;
    text-align: center;
    gap: 24px;
  }
  
  .label-cta-content {
    max-width: 100%;
  }
}

/* Feature comparison modal */
.feature-comparison-modal {
  max-width: 1000px;
  width: 90vw;
}

/* Selected plan info section in Unpaid component */
.selected-plan-info {
  margin-top: 24px;
  text-align: center;
  padding: 20px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  border-top: 3px solid #FF6B4A;
}

.selected-plan-info p {
  font-size: 16px;
  margin-bottom: 16px;
}

.selected-plan-info strong {
  color: #FF6B4A;
  font-weight: 700;
} 