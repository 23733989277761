.nav{
  top: 0;
  z-index: 4;
  width: 100%;
  height: 60px;
  position: fixed;
  padding: 13px 30px;
  text-align: center;
  padding-top: 16.5px;
  background-color: var(--color-black);
}
.sider-navbar {
  max-width: 250px !important;
  min-width: 250px !important;
  width: 250px !important;
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100%;
  z-index: 1000;
}
.priam-terms{
  margin: auto;
  padding: 5px 16px;
  font-size: 11px;
  position: absolute;
  bottom: 6px;
}
.priam-terms a:first-child{
  padding-left: 0;
}
.priam-terms a, .priam-terms span{
  color: #ffffffa6;
  padding: 0 5px;
}
.priam-terms a:hover{
  color: #ffffff;
  text-decoration: underline;
}
.overflow-fixer{
  overflow-y: auto;
  height: 100%;
}
.ant-layout-sider-collapsed {
  width: 80px !important;
  min-width: 80px !important;
  max-width: 80px !important;
}
.nav .logo{
height: 35px;
  vertical-align: middle;
}
.nabvar-on-register{
  text-align: center;
  padding: 37px 20px 25PX;
}
.logout-nav{
  color: var(--color-black);
  font-size: 16px;
  float: right;
  cursor: pointer;
  position: absolute;
  right: 30px;
  top: 49px;
  font-weight: 600;
  background-color: #f8f8fa;
  padding: 8px 16px;
  border-radius: 50px;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 6px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.06);
  border: 1px solid rgba(0, 0, 0, 0.05);
}
.logout-nav:hover{
  opacity: 1;
  background-color: #FF6B4A;
  color: white;
  box-shadow: 0 4px 12px rgba(255, 107, 74, 0.2);
  transform: translateY(-2px);
}
/********** Elements **********/
.nav .logoActive{
  height: 31px;
  min-width: 230px;
  display: inline-block;
  vertical-align: middle;
}
.a-img img.logoActive{
  width: 100%;
  padding: 0 25px;
  margin-top: 30px;
  margin-bottom: 25px;
  height: 45px;
}
.a-img img.logoActiveShort{
  width: 100%;
  padding: 0 23px;
  margin-top: 30px;
  margin-bottom: 25px;
  height: 45px;
}
.menu-el .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item{
  height: 62px;
}
.menu-el .ant-menu-item{
  padding-bottom: 13px !important;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected, .ant-menu-light .ant-menu-submenu-title:hover, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover, .ant-menu-horizontal:not(.ant-menu-dark)  > .ant-menu-submenu-active, .ant-menu-horizontal:not(.ant-menu-dark)  > .ant-menu-submenu-open, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected{
  color: var(--white-text);
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected:after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after, .ant-menu-horizontal:not(.ant-menu-dark)  > .ant-menu-submenu-active::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after{
  border-bottom: 3px solid var(--outrageouse-orange);
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  z-index: -1;
}
.ant-menu-horizontal > .ant-menu-item::after{
  right: 10px;
  left: 10px;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  border-bottom-width: 0px;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected{
  color: var(--white-text);
  height: 43.5px;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover:after, .ant-menu-horizontal > .ant-menu-item:hover::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after{
  border-bottom:  3px solid var(--outrageouse-orange);
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  z-index: -1;
}
.ant-menu-light .ant-menu-item:hover, .ant-menu-item-selected{
  color: var(--outrageouse-orange);
}
.nav .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: unset;
}
.ant-menu-submenu .ant-menu-sub, .ant-menu-inline.menu-el{
  background: rgba(39, 39, 39, 0.95);
  box-shadow: 0px 8px 8px -6px rgb(24 39 75 / 12%), 0px 8px 16px -6px rgb(24 39 75 / 8%);
  border-radius: 5px;
  color: rgba(255, 255, 255, 0.6);
  border: none;
}
.menu-el li.last-li{
  padding-left: 5px;
  padding-right: 0 !important;
}
.menu-el li.last-li:hover::after, .menu-el li.last-li:active::after, .menu-el li.last-li:focus::after, .menu-el li.last-li::after{
  border-bottom: none !important;
}
.active-btn .new-rel-btn{
  background-color: #ff4105;
}
.new-rel-btn {
  height: 44px;
  display: inline-block;
  line-height: 2;
  cursor: pointer;
  min-height: 40px;
  font-weight: 700;
  padding: 8px 0px 9px 0px;
  text-transform: capitalize;
  border-radius: 8px;
  margin-right: 0;
  margin-top: 2px;
  margin-bottom: 30px;
  width: 150px;
  text-align: center;
  vertical-align: top;
}
.new-rel-btn span{
  align-self: center;
  width: 150px;
  text-align: center;
}
.submenu-el span.ant-menu-item-icon{
  float: right;
  padding-top: 8px;
  padding-left: 4px;
  font-size: 9px;
}
.ant-menu-title-content:nth-child(2){
  margin-left: 0 !important;
}
/********** Side Menu **********/
.side-btn{
  display: none;
  margin: 0;
  text-align: center;
  width: auto;
  border: none;
  color: var(--color-black);
  border-radius: 5px;
  width: 42px;
  height: 42px;
  padding-top: 4px;
  margin-right: 14px;
  box-shadow: none;
}
.side-btn span{
  font-size: 22px;
}
.side-btn:hover, .side-btn:active, .side-btn:focus{
  color: var(--color-black);
  opacity: 0.8;
}
.ant-menu-inline>.ant-menu-item {
  height: 45px;
  line-height: 45px;
}
.ant-menu-inline.menu-el{
  width: 195px;
  position: relative;
  right: 30px;
  top: 5px;
  border-top-left-radius: 0;
}
.ant-menu-inline .ant-menu-submenu-title .anticon-down{
  display: none;
}
.ant-menu-inline .ant-menu-submenu-title .ant-menu-submenu-arrow, .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-expand-icon, .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow{
    color: rgba(255, 255, 255, 0.6);
}
.ant-menu-inline .ant-menu-item::after{
  border-right: 3px solid var(--outrageouse-orange);
  right: 1px;
}
.ant-menu-light .ant-menu-item:hover, .ant-menu-light .ant-menu-item-active, .ant-menu-submenu-selected{
  color: var(--outrageouse-orange);
}
.ant-menu-horizontal > .ant-menu-item a, .ant-menu-item a{
  color:  #fbfbfb99
}
.ant-menu-horizontal > .ant-menu-item a:hover, .ant-menu-horizontal > .ant-menu-item-selected a, .ant-menu-item-selected a, .ant-menu-item-selected a:hover{
  color: var(--white-text);
}
.menu-el li:nth-last-child(2){
  margin-top: -2px !important;
}
/********** BreadCrumb **********/
.sub-section-nav{
  font-size: 25px;
  text-align: left;
}
/********** User **********/
.user-element{
  display: flex;
  padding: 15px 60px 15px 60px ;
  justify-content: space-between;
  background: linear-gradient(0deg, rgb(255 255 255 / 95%) 0%, rgba(255, 255, 255, 1) 80%);
  box-shadow: var(--shadow-header);
  position: fixed;
  top: 0;
  width: 100%;
  height: 70px;
  z-index: 999;
  padding-left: 310px;
  transition: padding-left 0.3s;
} 
.user-element-coll{
  padding-left: 141px;
  transition: padding-left 0.3s;
}
.user-element h1.title{
  font-size: 25px;
  font-weight: 700;
  margin: 0;
  padding-right: 30px;
}
.avatar-default{
  font-size: 29px;
  background-color: rgba(39, 39, 39, 0.025);
  border-radius: 50%;
  padding: 4px;
  margin-right: 10px;
  vertical-align: middle;
}
.avatar-img {
  border-radius: 50%;
  margin-right: 10px;
  vertical-align: middle;
  width: 40px;
  height: 40px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: inline-block;
}
.user-element .user {
  margin: 0;
  padding: 0;
  border: none;
  width: auto;
  cursor: pointer;
  font-weight: 500;
  background: unset;
  display: inline-block;
  color: #454545e6;
  border-radius: 50px;
  position: relative;
}
.name {
  font-size: 16px;
}
.user .name{
  max-width: 95px;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  vertical-align: middle;
}
.user .down{
  display: none;
  margin-left: 5px;
  vertical-align: middle !important;
  font-size: 14px !important;
}
.ant-dropdown-menu{
  background: rgba(255, 255, 255, 0.95);
  box-shadow: 0px 8px 8px -6px rgb(24 39 75 / 12%), 0px 8px 16px -6px rgb(24 39 75 / 8%);
  border-radius: 5px;
  border: 1px solid #dadce0;
  padding: 0;
}
.ant-dropdown-menu li{
  color: rgba(69, 69, 69, 0.6);
  min-width: 140px;
}
.ant-dropdown-menu li:nth-child(2){
  border-top: 1px solid #dadce0;
}
.myprofile-item{
  font-weight: 800;
}
.item-list .alert-top{
  top: 13px;
}
.alert-payee{
  width: 8px;
  height: 8px;
  background-color: var(--outrageouse-orange);
  border-radius: 50%;
  position: absolute;
  left: 27px;
  top: 2px;
}
/********** Sider Navbar **********/
.ant-layout-sider-children{
  background: radial-gradient(circle at -8.9% 51.2%, rgb(0, 15, 22) 0%, rgb(0, 15, 22) 66%);
}
.sider-navbar, .sider-navbar .ant-layout-sider-children ul, .drawer-mobile ul.ant-menu.ant-menu-dark{
  background: radial-gradient(circle at -8.9% 51.2%, rgb(0, 15, 22) 0%, rgb(0, 15, 22) 66%);
}
.sider-navbar svg, .drawer-mobile svg{
  width: 18px;
  height: 18px;
  margin-right: 15px;
}
.sider-navbar .ant-layout-sider-children ul, .drawer-mobile ul.ant-menu{
  padding-bottom: 62px;
}
.ant-layout-sider.sider-navbar ul li, .drawer-mobile ul li.ant-menu-item{
  margin-top: 15px;
}
.ant-layout-sider.sider-navbar .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected, .drawer-mobile .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected{
  background: radial-gradient(circle at -8.9% 51.2%, rgb(0, 15, 22) 0%, rgb(0, 15, 22) 66%);
  border-radius: 4px;
}
.ant-layout-sider-collapsed .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item, .drawer-mobile .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item{
  padding-top: 5px;
}
.ant-layout-sider.sider-navbar .ant-menu-dark .ant-menu-item:hover, .drawer-mobile .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected:hover {
  background-color: rgba(255, 255, 255, 0.06);
}
.ant-layout-sider.sider-navbar .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected::after, .drawer-mobile .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected::after{
  border-right: 4px solid var(--outrageouse-orange);
  right: 0px;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
.sider-navbar .ant-menu-inline-collapsed li.ant-menu-item-selected{
  border-right: 4px solid var(--outrageouse-orange);
}
.sider-navbar .ant-menu-item-selected svg path, .sider-navbar .ant-menu-item-selected svg rect{
  fill: #fff;
}
.divisor-sider{
  height: 3px;
  background: var(--gradient-1, linear-gradient(90deg, #FF5F2E 0%, #FF9FFF 53.65%, #73C7FF 100%, #73C7FF 100%));
  border: none;
  margin-bottom: 20px;
}
/********** Drawer Mobile **********/
.drawer-mobile{
  display: none;
}
.drawer-mobile .ant-drawer-content, .drawer-mobile .ant-drawer-content-wrapper{
  width: 250px !important;
  background-color: #1C1C1C;
}
.drawer-mobile .ant-drawer-body{
  padding: 0px;
  background: radial-gradient(circle at -8.9% 51.2%, rgb(0, 15, 22) 0%, rgb(0, 15, 22) 66%)
}
/********** Open Button **********/
.collapse-btn{
  position: absolute;
  top: 0;
  right: -13px;
  width: auto;
  margin-top: 25px;
  background:  linear-gradient(135deg, var(--outrageouse-orange), #ff4500, #ff7f50) !important;
  border-radius: 50px;
  width: 26px;
  height: 26px;
  padding-left: 6px;
  z-index: 2;
}
.collapse-btn span{
  width: inherit;
}
.collapse-btn svg{
  font-size: 20px;
}
.icon-menu-nav{
  margin: 0 10px 10px 0px;
  vertical-align: text-top;
}
/********** Medias **********/
@media(min-width: 1021px) and (max-width: 1053px){
  .menu-el .ant-menu-item{
    margin-right: 17px;
  }
}
@media (max-width: 880px){
  .user .name{
    display: none;
  }
}
@media (max-width: 785px){
  .user-element {
    width: 100%;
    margin: auto;
    display: flex;
    padding: 15px 30px 15px 30px;
    text-align: center;
    align-items: center;
    margin-top: 0;
    padding-left: 310px;
  }
  .user-element-coll{
    padding-left: 141px;
  }
  .alert-p{
    top: 14px;
  }
  .user-element h1.title{
    padding-right: 0;
    text-align: center;
    padding-top: 6px;
  }
  .user-element .user{
  text-align: right;
  display: inline-flex;
  align-items: center;
  }
  .avatar-default{
    margin-right: 10px;
  }
  .user .down{
    display: inline-flex;
  }
  .drop-user ul li.ant-dropdown-menu-item{
    min-height: 45px;
    min-width: 140px;
  }
}
@media (max-width: 700px){
  .logout-nav{
    position: absolute;
    display: flex;
    width: auto;
    text-align: center;
    top: 37px;
    right: 20px;
    font-size: 14px;
    padding: 6px 12px;
  }
  .user-element h1.title, .sub-section-nav{
    font-size: 20px;
  }
}
@media (max-width: 550px){
  .nav{
    padding: 13px 10px;
  }
  .ant-menu-inline.menu-el{
    right: 10px;
  }
  .new-rel-btn{
    width: 100%;
  }
  .side-btn{
    display: block;
  }
  .user-element {
    padding: 15px 30px 15px 30px;
  }
  .sider-navbar{
    display: none;
  }
  .drawer-mobile{
    display: block;
  }
  .drawer-mobile .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item{
    padding-top: 0px !important;
  }
}
@media (max-width: 475px){
  .user-element h1.title, .sub-section-nav{
    font-size: 15px;
  }
}
@media (max-width: 310px){
  .nabvar-on-register {
    padding: 25px 40px;
  }
  .nabvar-on-register img{
    width: 100%;
  }
}
@media (max-width: 400px){
  .logout-nav{
    padding: 4px 10px;
    font-size: 13px;
  }
  .logout-nav .anticon {
    font-size: 12px;
  }
  .nabvar-on-register {
    padding: 37px 10px 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .nabvar-on-register img.logo {
    height: 30px;
  }
}
@media (max-height: 615px){
  .priam-terms.usr-bar{
    position: relative;
  }
}
@media (max-height: 846px){
  .priam-terms.admin-bar{
    position: relative;
  }
}