.ant-input-prefix {
    margin-right: 6px;
}
.ant-input::placeholder{
    color:  #898686;
}
.input-general input, .text-area-general textarea{
  font-size: 16px;
}
.input-general:hover{
    border: 1px solid var(--malibu);
}
.input-general.input-general-error{
  border: 1px solid #FD6A88 !important;
}
.selector-general.input-general-error .ant-select-selector{
  border: 1px solid #FD6A88 !important;
}
.add-top .ant-select-selection-placeholder{
    padding-top: 4px !important;
}
.inline-input{
  display: flex;
}
.inline-input .label-upc{
  height: 40px;
  vertical-align: middle;
  align-content: center;
  padding-right: 10px;
}
.inline-input .selector-general{
  display: inline-block;
  flex: 1 1 auto;
}
.ant-picker.input-general-error{
  border: 1px solid #FD6A88 !important;
}

.text-area-general textarea{
    background-color: #fff;
}
.input-general .anticon svg{
    width: 18px;
    height: 16px;
    vertical-align: middle;
    margin-top: -2px;
}
.input-general.email input::placeholder{
    text-transform: capitalize;
}
.input-general.email:hover{
    border: 1px solid var(--malibu);
}
.forms img{
    border-radius: 5px
}
/********* CheckboxGroup **********/
.text-error.group-error{
    position: relative;
    top: 0;
}
.ant-checkbox-indeterminate .ant-checkbox-inner:after{
    background-color: var(--malibu);
    border-radius: 1.2px;
}
/********** Select Input **********/
.input-general, .selector-general .ant-select-selector, .text-area-general textarea, .ant-picker, .selector-general.ant-select:not(.ant-select-customize-input) .ant-select-selector{
    border: 1px solid #DADCE0;
    color: #898686;
    min-height: 40px;
    border-radius: 5px !important;
    margin-bottom: 15px;
    background-color: #fff !important;
}
.input-general:focus, .selector-general .ant-select-selector:focus, .selector-general.ant-select:not(.ant-select-disabled):hover .ant-select-selector, .text-area-general textarea:focus, .ant-input-affix-wrapper-focused, .ant-input-affix-wrapper:focus, .ant-input-focused, .ant-input:focus, .ant-picker-focused,.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector{
    box-shadow: none;
    border-color: var(--malibu);
}

.input-general.pwd .ant-input, .input-general.email .ant-input{
    background-color: #fff;
}
.input-general.pwd:hover{
    border: 1px solid var(--malibu);
}
.ant-select-selection-item {
    padding-top: 5px !important;
}
.ant-select-margin {
    margin-top: 15px;
    margin-bottom: 10px;
}

.selector-general .ant-select-single.ant-select-show-arrow .ant-select-selection-item{
    padding-top: 5px;
    padding-bottom: 5px;
}
.selector-general .ant-select-arrow{
    top: 21px;
}
.selector-general.countErr .ant-select-selector{
    box-shadow: 0px 0px 3px #FD6A88 !important;
    border: 1px solid #FD6A88 !important;
}
.selector-general .ant-select-clear{
    top: 19px;
}
.selector-general .ant-select-selector .ant-select-selection-search .ant-select-selection-search-input{
    padding-top: 8px;
}
.zipdiv + .text-error{
    top: 41px;
}
.selector-general.multiselect{
    opacity: 0;
    animation: opacityOn 0.5s normal forwards;
}
.multiselect.ant-select-multiple .ant-select-selection-item{
  padding-top: 0px !important;
}
.multi.ant-select-multiple .ant-select-selection-item{
    padding-top: 0px !important;
    background: none;
    border: none;
}
.selector-general.multiselect .ant-select-selection-overflow{
    opacity: 0;
    animation: opacityOn 1s normal forwards;
}
@keyframes opacityOn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
.ant-upload.ant-upload-select-picture-card {
    border: 1px solid #dadce0;
}
.new-list-artist{
  color: rgba(0,0,0,.85);
  cursor: pointer;
  padding: 11px 0;
}
/********** Phone Input **********/
.phone-input{
    position: relative;
}
.phone-input label{
    display: block;
}
.phone-input .phone-div{
    display: flex;
}
.phone-input .selector-general{
    margin-right: 10px;
}
.phone-input .selector-general .ant-select-selection-item{
    padding-right: 14px;
}
.phone-input .selector-general .ant-select-selector{
    width: 87px;
    text-align: right;
    padding-left: 0px;
    padding-right: 8px;
}
.phone-input .selector-general .ant-select-arrow{
    right: 16px;
}
.phone-input .text-error{
    right: 0;
}
.general_information .user-lada, .general_information .user-lada .ant-select-selector{
    min-height: 30px;
    height: 30px;
    border: none !important;
    color: rgba(0,0,0,.85);
}
.general_information .user-lada .ant-select-arrow{
    top: 50%;
}
.general_information .user-phone{
    min-height: 30px;
    height: 30px;
    border: none !important;
    color: rgba(0,0,0,.85);
    margin-bottom: 0px;
}
.general_information .user-phone.ant-input[disabled]{
    border: none !important;
    border-radius: 8px !important;
    background: #fafafa;
}
/********* Global Inputs *********/
.ant-radio-checked::after{
    border: 1px solid var(--malibu);
}
.ant-radio-inner::after{
    background-color: var(--malibu);
}
.ant-radio-checked .ant-radio-inner, .ant-radio-wrapper:hover .ant-radio, .ant-radio:hover .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner{
    border-color: var(--malibu);
}
.ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--malibu);
    border-color: var(--malibu);
}
.ant-checkbox-checked::after{
    border: 1px solid var(--malibu);
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner{
    border-color: var(--malibu);
}
.check-addTo {
  margin-top: 14px;
}
/********* DatePicker *********/
.forms .ant-picker{
    border: 1px solid #DADCE0;
    color: #898686;
    min-height: 40px;
    border-radius: 5px;
    margin-bottom: 15px;
    width: 100%;
    background-color: #fff;
}
.forms .ant-picker input{
    color: #898686;
}
.suffix-text{
    position: absolute;
    top: calc(100% - 51px);
    right: 30px;
    font-size: 14px;
    color: #565656;
}
.date-picker-inp input{
    color: #898686;
}
/********** Search Input **********/
.title-catalog .filters .relative-input .input-general.searcher{
    border-radius: 8px !important;
}
.searcher input{
    border: none !important;
    color: #898686;
    min-height: 48px;
    background-color: #fff;
    margin-bottom: 15px;
    padding: 9px 20px;
    border-radius: 8px !important;
}
.input-general.searcher input:focus{
    box-shadow: none;
}
.input-general.searcher span.ant-input-group:focus{
    box-shadow: 0px 0px 3px var(--malibu) !important;
    border-radius: 8px;
}
.searcher .ant-input-group-addon .ant-input-search-button {
    position: absolute;
    top: 10px;
    border: none;
    background: transparent;
    z-index: 1;
    width: 30px;
    box-shadow: none;
    padding: 0;
    background-color: transparent;
    right: 21px;
}
.ant-upload.ant-upload-select-picture-card:hover{
    border: 1px solid var(--malibu);
}
/********* Errors *********/
.inputErr, .inputErrMulti .ant-select-selector{
    box-shadow: 0px 0px 3px var(--error-field) !important;
}
.inputErrBorder{
    border: 1px solid var(--error-field) !important;
}
.text-error{
    bottom: 9px;
    color: var(--error-field);
    font-size: 11px;
    font-weight: bold;
    position: relative;
}
.text-error.relative-text{
    position: relative;
    top: unset;
}
.error-field{
    color: var(--error-field);
    font-size: 17px;
    font-weight: bold;
    text-align: center;
    padding-top: 10px;
}
.multitext-error {
    bottom: 9px;
    color: var(--error-field);
    font-size: 11px;
    font-weight: bold;
    position: relative;
}
.head-form-error{
    color: var(--error-field);
    font-size: 15px;
}
