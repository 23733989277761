.custom-card-wrapper {
    width: 151px;
    display: flex;
    flex-direction: column;
    margin-right: 5px;
    position: relative;
}

.custom-card-container,
.custom-card-link {
    display: block;
    text-decoration: none;
    text-decoration-color: none;
    text-decoration-style: none;
    color: #4c4c4c;
}
.custom-card-link:hover{
    color: #4c4c4c;
}

.custom-card-image-container {
    width: 151px;
    height: 151px;
    flex: 1;
    overflow: hidden;
    border-radius: 10px;
    background-color: var(--color-container);
}

.custom-card-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.custom-card-avatar {
    width: 100% !important;
    height: 100% !important;
}

.custom-card-content {
    flex: 1;
}

.custom-card-title {
    width: 151px;
    font-size: 13px;
    font-weight: 500;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-top: 7px;
}

.custom-card-subtitle {
    font-size: 10px;
    color: var(--subtext);
    font-weight: 500;
    bottom: 8px;
}

.custom-card-badge-container {
    display: flex;
    justify-content: flex-end;
    min-height: 12px;
    max-height: 12px;
    position: inherit;
}

.custom-card-badge{
    position: relative;
    right: -10px;
    top: 4px;
    cursor: pointer;
}

.custom-card-actions {
    position: absolute;
    top: 8px;
    right: 8px;
    z-index: 2;
    display: none;
}

.custom-card-wrapper:hover .custom-card-actions {
    display: flex;
    gap: 8px;
}

.custom-card-plus {
    width: 151px;
    height: 151px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-container);
    border-radius: 10px;
    cursor: pointer;
}

.album{
    width: 151px;
    font-size: 13px;
    font-weight: 500;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-top: 7px;
    margin:0;
}

.artist{
    font-size: 13px;
    color: var(--color-title);
    text-decoration-color: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 151px;
    overflow: hidden;
    margin: 0;
    justify-content: center;
}
.custom-card-fade-background{
    position: absolute;
    width: 151px;
    height: 151px;

    visibility: hidden;
    opacity: 0;

    display: flex;
    border-radius: 10px;
    align-items: center;
    background: #000000a6;
    justify-content: space-evenly;
    transition: visibility 0s, opacity 0.3s linear;
    -o-transition:  visibility 0s, opacity 0.3s linear;
    -moz-transition: visibility 0s, opacity 0.3s linear;
    -webkit-transition: visibility 0s, opacity 0.3s linear;
}


.fade-background-icons{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 151px;
    height: 151px;
}

.custom-card-background-image-fadebg:hover .custom-card-fade-background.show {
    opacity: 1;
    visibility: visible;
}
.custom-card-background-image-fadebg:focus .custom-card-fade-background.show-mobile {
    opacity: 1;
    visibility: visible;
}

.custom-card-background-image-fadebg svg:hover {
    transform: scale(1.1);
    transition: transform 0.2s ease;
}

.fade-icon {
    color: #fff;
    font-size: 25px;
    cursor: pointer;
    transition: transform 0.2s ease;
}

.fade-icon:hover {
    transform: scale(1.1);
}